import { FunctionComponent, ReactNode } from "react";
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";

import { authSelector } from "@/pages/Public/redux/auth.slice";
import { setActiveBar } from "@/pages/Private";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { useAppSelector } from "@/app/redux/hooks";

import { getAllUserPermissions } from "@/app/utils";

import { Features } from "@/pages/Private/pages/Companies/enums";
import { PARSED_ENV } from "@/app/constants/common";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { routes } from "./config";

const navItemStyles = {
  textAlign: "center",
  fontWeight: 700,
  fontSize: { xs: "24px", md: "14px" },
  width: "max-content",
};

const NavItem: FunctionComponent<{
  text: ReactNode;
  path: string;
  isActive?: boolean;
  externalLink?: boolean;
}> = ({ text, path, isActive, externalLink }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <ListItem
      key={text?.toString()}
      disablePadding
      sx={{ paddingTop: path === ROUTE_CONFIG.PROJECT_REPORTING ? "12px" : "" }}
      onClick={() => dispatch(setActiveBar(null))}
    >
      <ListItemButton
        component={Link}
        rel={externalLink ? "noopener" : ""}
        sx={{
          display: "flex",
          flexDirection: "column",
          color: {
            xs: path === ROUTE_CONFIG.LOGOUT ? "red.main" : "gray600.main",
            md: isActive ? "main" : "gray600.main",
          },
          ...navItemStyles,
        }}
        target={externalLink ? "_blank" : ""}
        to={path}
      >
        <ListItemText
          disableTypography
          primary={text}
          sx={{ marginBottom: path === ROUTE_CONFIG.PROJECT_REPORTING ? 0 : "4px" }}
        />
        {path === ROUTE_CONFIG.PROJECT_REPORTING && (
          <Typography
            sx={{
              width: "100%",
              display: "flex",
              color: "main",
              justifyContent: "end",
              fontSize: { xs: "15px", md: "10px" },
              fontWeight: 1000,
            }}
          >
            {t("basics.beta")}
          </Typography>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export const Navigation: FunctionComponent = () => {
  const location = useLocation();

  const { user } = useAppSelector(authSelector);

  const userPermissions = user ? getAllUserPermissions(user.roles) : [];
  const userRoutes = routes.user.filter((route) => userPermissions.includes(route.permission));
  const adminRoutes = routes.bSureAdmin.filter((route) =>
    userPermissions.includes(route.permission)
  );

  const isMultiTenancyActive = useIsFeatureActive(Features.MULTI_TENANCY);
  const isProjectReportingActive = useIsFeatureActive(Features.PROJECT_REPORTING);

  const getCockpitUrl = (): string | false => {
    if (window.location.host === "dev-jti.bsure-analytics.de") {
      return "https://dev-cockpit-jti.bsure-analytics.de";
    } else if (window.location.host === "jti.bsure-analytics.de") {
      return "https://cockpit-jti.bsure-analytics.de";
    }

    return PARSED_ENV.REACT_APP_COCKPIT_URL ?? false;
  };

  const cockpitUrl = getCockpitUrl();

  return (
    <List
      disablePadding
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        height: { xs: "80%", md: "" },
      }}
    >
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          marginRight: { md: 4 },
        }}
      >
        {userRoutes.map((route, i) =>
          // It's implemented the following way since only the Project Reporting menu is currently
          // depending on a feature flag and the rest of the menus are not (temporary solution)
          route.path === ROUTE_CONFIG.PROJECT_REPORTING && !isProjectReportingActive ? null : (
            <NavItem
              key={`user-nav-${i}`}
              isActive={!!matchRoutes([{ path: `${route.path}/*` }], location)}
              path={route.path}
              text={route.text}
            />
          )
        )}
        {isMultiTenancyActive &&
          adminRoutes.map((route, i) => (
            <NavItem
              key={`bSureAdmin-nav-${i}`}
              isActive={!!matchRoutes([{ path: `${route.path}/*` }], location)}
              path={route.path}
              text={route.text}
            />
          ))}
        {cockpitUrl &&
          routes.externalLink.map((route, i) => (
            <NavItem
              key={`externalLink-nav-${i}`}
              externalLink={typeof cockpitUrl === "string"}
              path={cockpitUrl}
              text={
                <Box sx={{ display: { md: "flex" }, alignItems: "center" }}>
                  {route.text}
                  <OpenInNewIcon fontSize="small" />
                </Box>
              }
            />
          ))}
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
        }}
      >
        {routes.common.map((route, i) => (
          <NavItem
            key={`common-nav-${i}`}
            isActive={!!matchRoutes([{ path: `${route.path}/*` }], location)}
            path={route.path}
            text={route.text}
          />
        ))}
      </Box>
    </List>
  );
};
