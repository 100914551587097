import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { Festival, Museum, Storefront } from "@mui/icons-material";

import { TableColumn, TableComponent } from "@/components";
import { MetaData } from "@/schemas/meta-data";
import { WarningIcon } from "@/assets";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import { EventModuleType } from "@/pages/Private/pages/Modules/types";
import { RadialBarChart } from "@/components/RadialBarChart/RadialBarChart";

import { EventType } from "../../../event.schema";
import { EventHeader } from "./EventHeader";
import { EventDuration } from "./EventDuration";
import { NumbersCompares } from "./NumbersCompares";
import { EventTypes, KpiTypeEnum } from "../../../types";

interface EventsTableProps {
  events: EventType[];
  page: number;
  handleFetchPage: (page: number) => void;
  meta?: MetaData;
  isLoading?: boolean;
}

export const EventModuleTypeIcon: FunctionComponent<{
  type?: string | number;
  smallSize?: boolean;
}> = ({ type, smallSize }) => {
  switch (type) {
    case EventTypes.FESTIVAL:
    case EventTypes.PLOOM_FESTIVAL:
    case EventModuleType.FESTIVAL_MODULE:
      return (
        <Festival
          sx={{
            color: "main",
            fontSize: smallSize ? "20px" : "28px",
            marginRight: smallSize ? 0.5 : 0,
          }}
        />
      );
    case EventTypes.MOBILE:
    case EventModuleType.MOBILE_LOCATION:
    case EventModuleType.MOBILE_TEAM:
      return (
        <Museum
          sx={{
            color: "main",
            fontSize: smallSize ? "20px" : "28px",
            marginRight: smallSize ? 0.5 : 0,
          }}
        />
      );
    case EventTypes.STATIONARY:
    case EventModuleType.STATIONARY_TOUCHPOINT:
      return (
        <Storefront
          sx={{
            color: "main",
            fontSize: smallSize ? "20px" : "28px",
            marginRight: smallSize ? 0.5 : 0,
          }}
        />
      );

    default:
      return <></>;
  }
};

export const EventsTable: FunctionComponent<EventsTableProps> = ({
  events,
  page,
  handleFetchPage,
  meta,
  isLoading,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const { kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector); // TECH DEBT - BA-706

  const isSalesActive = useIsFeatureActive(Features.KPI_TILE_SALES);
  const isDOINLActive = useIsFeatureActive(Features.KPI_TILE_DIO_NL);
  const isSamplingActive = useIsFeatureActive(Features.KPI_TILE_SAMPLES);
  const isKPITabActive = useIsFeatureActive(Features.KPI_TAB);
  const isKpiWeightingActive = useIsFeatureActive(Features.KPI_WEIGHTING);

  const columns: TableColumn<EventType>[] = [
    {
      title: t("basics.brand"),
      width: "auto",
      align: "center",
      render: (event) => (
        <>
          {event?.brand?.image ? (
            <Box height={28} mr={1} width={28}>
              <img
                alt="#"
                src={event.brand.image}
                style={{ width: "100%", height: "100%", borderRadius: "100%" }}
              />
            </Box>
          ) : (
            <Box bgcolor="main" borderRadius="100%" height={28} mr={1} width={28}></Box>
          )}
        </>
      ),
    },
    {
      title: t("events.eventType"),
      width: "auto",
      render: (event) => <EventModuleTypeIcon type={event.eventType} />,
    },
    {
      title: t("basics.name"),
      width: "auto",
      render: (event) => <EventHeader alignCenter withStatus event={event} />,
    },
    {
      title: t("terms.project"),
      width: "auto",
      render: (event) => <Typography variant="main14">{event.project?.name || "-"}</Typography>,
    },
    {
      title: ts("timeFrame"),
      width: "auto",
      render: (event) => (
        <EventDuration
          dayArrCount={event.eventDates.length}
          endDate={event.endDate}
          startDate={event.startDate}
        />
      ),
    },
    {
      title: ts("visitors"),
      width: "auto",
      render: (event: EventType) => {
        const visitors = event.goals?.find((g) => g.type === KpiTypeEnum.VISITORS);

        return (
          <NumbersCompares
            actual={visitors?.actual || 0}
            isKpiFeatureActive={true} //must adjust if feature flag is added for visitors
            isKpiSettingActive={visitors?.isActive}
            planned={(kpiTarget2TypeSwitchedOn ? visitors?.planned2 : visitors?.planned) || 0} // TECH DEBT - BA-706
          />
        );
      },
    },
    ...(isSalesActive
      ? [
          {
            title: ts("sales"),
            width: "auto",
            render: (event: EventType) => {
              const sales = event.goals?.find((g) => g.type === KpiTypeEnum.SALES);
              const evoSales = event.goals?.find(
                (g) => g.type === KpiTypeEnum.SALES_BY_CIGARETTESTYPE_ECSCATEGORY
              );
              const isPloomEvent = event.eventType !== EventTypes.FESTIVAL;

              return isPloomEvent ? (
                <NumbersCompares
                  actual={evoSales?.actual || 0}
                  isKpiFeatureActive={isKPITabActive}
                  isKpiSettingActive={evoSales?.isActive}
                  isPloomValue={isPloomEvent}
                  planned={(kpiTarget2TypeSwitchedOn ? evoSales?.planned2 : evoSales?.planned) || 0} // TECH DEBT - BA-706
                  titleLabel={ts("electronicCigaretteSticks")}
                />
              ) : (
                <NumbersCompares
                  actual={sales?.actual || 0}
                  isKpiFeatureActive={isKPITabActive}
                  isKpiSettingActive={sales?.isActive}
                  planned={(kpiTarget2TypeSwitchedOn ? sales?.planned2 : sales?.planned) || 0} // TECH DEBT - BA-706
                />
              );
            },
          },
        ]
      : []),
    {
      title: ts("deviceSales"),
      width: "auto",
      render: (event: EventType) => {
        const isFestivalEvent = event?.eventType === EventTypes.FESTIVAL;
        const samples = event.goals?.find((g) => g.type === KpiTypeEnum.SALES_BY_DEVICE);
        const isPloomEvent = event.eventType !== EventTypes.FESTIVAL;

        return isFestivalEvent ? (
          <Typography variant="gray14">{t("basics.notApplicable")}</Typography>
        ) : (
          <NumbersCompares
            actual={samples?.actual || 0}
            isKpiFeatureActive={isKPITabActive}
            isKpiSettingActive={samples?.isActive}
            isPloomValue={isPloomEvent}
            planned={(kpiTarget2TypeSwitchedOn ? samples?.planned2 : samples?.planned) || 0} // TECH DEBT - BA-706
          />
        );
      },
    },
    ...(isDOINLActive
      ? [
          {
            title: t("events.kpis.NEWSLETTER_DATA"),
            width: "auto",
            render: (event: EventType) => {
              const doiNL = event.goals?.find((g) => g.type === KpiTypeEnum.NEWSLETTER_DATA);
              const isPloomEvent = event.eventType !== EventTypes.FESTIVAL;

              return (
                <NumbersCompares
                  actual={doiNL?.actual || 0}
                  isKpiFeatureActive={isKPITabActive}
                  isKpiSettingActive={doiNL?.isActive}
                  isPloomValue={isPloomEvent}
                  planned={(kpiTarget2TypeSwitchedOn ? doiNL?.planned2 : doiNL?.planned) || 0} // TECH DEBT - BA-706
                  titleLabel={ts("withDeviceId")}
                />
              );
            },
          },
        ]
      : []),
    ...(isSamplingActive
      ? [
          {
            title: ts("sampling"),
            width: "auto",
            render: (event: EventType) => {
              const samples = event.goals?.find((g) => g.type === KpiTypeEnum.SAMPLES);

              return (
                <NumbersCompares
                  actual={samples?.actual || 0}
                  isKpiFeatureActive={isKPITabActive}
                  isKpiSettingActive={samples?.isActive}
                  planned={(kpiTarget2TypeSwitchedOn ? samples?.planned2 : samples?.planned) || 0} // TECH DEBT - BA-706
                />
              );
            },
          },
        ]
      : []),
    {
      title: ts(isKpiWeightingActive ? "kpiWeightedScore" : "kpiAverageScore"),
      width: "100px",
      align: "center",
      render: (event: EventType) => {
        return (
          <Box margin="0 auto" width="fit-content">
            <RadialBarChart
              series={[
                kpiTarget2TypeSwitchedOn
                  ? event.kpiScore?.planned2KpiScore
                  : event.kpiScore?.plannedKpiScore,
              ]}
            />
          </Box>
        );
      },
    },
    ...(isKPITabActive
      ? [
          {
            title: t("basics.settings"),
            width: "auto",
            render: (event: EventType) => {
              const allCurrEventKPIs = event.goals
                ?.filter((kpi) => !event.brand?.company.config?.kpiHiddenPlanned.includes(kpi.type))
                .filter((k) => k.isActive);
              const plannedValuesSet = allCurrEventKPIs?.filter(
                (g) => g.planned && g.isActive
              )?.length;
              const isWarning = plannedValuesSet !== allCurrEventKPIs?.length;

              return (
                <Box alignItems="center" display="flex">
                  {isWarning && <WarningIcon />}
                  <Typography
                    color={isWarning ? "warning.main" : "main"}
                    fontWeight={700}
                    ml={isWarning ? 1 : 0}
                    variant="main14"
                  >
                    {plannedValuesSet}/{allCurrEventKPIs?.length}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: ts("finalKPIs"),
            width: "auto",
            render: (event: EventType) => {
              const activeKPIs = event.goals?.filter((g) => g.isActive).length;
              const finalizedKPIs = event.goals?.filter((g) => !!g.completed && g.isActive).length;
              const isWarning = finalizedKPIs !== activeKPIs;

              return (
                <Box alignItems="center" display="flex">
                  {isWarning && <WarningIcon />}
                  <Typography
                    color={isWarning ? "warning.main" : "main"}
                    fontWeight={700}
                    ml={isWarning ? 1 : 0}
                    variant="main14"
                  >
                    {finalizedKPIs}/{activeKPIs}
                  </Typography>
                </Box>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <TableComponent
      allowNavigation
      columns={columns}
      data={events}
      handleFetchPage={handleFetchPage}
      isLoading={isLoading}
      page={page}
      totalPages={meta?.pageCount ?? 1}
    />
  );
};
