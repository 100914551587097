import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ArrowBackIosNewRounded } from "@mui/icons-material";

import { formatEventDates } from "@/app/utils/helpers";
import { ROUTE_CONFIG } from "@/app/routes/config";

import { RadialBarChart } from "@/components/RadialBarChart/RadialBarChart";
import { useAppSelector } from "@/app/redux/hooks";
import { eventsSelector } from "@/pages/Private/redux";
import { useWindowSize } from "@/app/hooks/useWindowSize";
import { Features } from "@/pages/Private/pages/Companies/enums";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";

import { EventStatusBox } from "../../../components/EventStatus";
import { EventType } from "../../../../event.schema";

interface EventHeaderProps {
  event: EventType;
}

export const EventDetailHeader: FunctionComponent<EventHeaderProps> = ({ event }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { width } = useWindowSize();

  const { eventDetails, kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector); // TECH DEBT - BA-706
  const isKpiWeightingActive = useIsFeatureActive(Features.KPI_WEIGHTING);

  const { name, startDate, endDate, image, status, brand } = event;

  const kpiScore = eventDetails
    ? kpiTarget2TypeSwitchedOn
      ? eventDetails.kpiScore?.planned2KpiScore
      : eventDetails.kpiScore?.plannedKpiScore
    : 0;

  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        bgcolor: "white",
        borderRadius: "0 0 8px 8px",
        boxShadow: 1,
        height: width > 400 ? "75px" : "95px",
        m: -2,
        mb: { xs: 1, sm: 2, md: 3 },
        p: "12px 16px",
        flexGrow: 2,
        width: width > 400 ? 400 : "100%",
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        mr={2}
        mt={0.6}
        onClick={() => navigate({ pathname: ROUTE_CONFIG.EVENTS, search: searchParams.toString() })}
      >
        <ArrowBackIosNewRounded />
      </Box>
      <Box alignItems="center" display="flex">
        <img
          alt="#"
          src={image}
          style={{ width: 54, height: 54, borderRadius: 8, marginRight: 8, objectFit: "cover" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box alignItems="center" display="flex" sx={{ marginBottom: 0.5 }}>
            <Typography component="p" sx={{ marginRight: 0.5 }} variant="main">
              {name}
            </Typography>
            <img
              alt="#"
              src={brand?.image}
              style={{
                width: 18,
                height: 18,
                borderRadius: 8,
              }}
            />
          </Box>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography component="p" variant="gray12">
              {formatEventDates({ startDate, endDate })}
            </Typography>
            <EventStatusBox status={status} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: 1,
          borderStyle: "solid",
          borderWidth: "0 0 0 1px",
          borderColor: "gray400.main",
          paddingLeft: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Typography variant="gray14Bold">
          {t(isKpiWeightingActive ? "events.kpiWeightedScore" : "events.kpiAverageScore")}
        </Typography>
        <RadialBarChart isMobile={true} series={[kpiScore]} />
      </Box>
    </Box>
  );
};
