import { FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { clearSelectedProjectByBrand } from "@/pages/Private/redux/projects.slice";
import { LoadingOverlay } from "@/components";
import { useGetProjectByBrandKpiQuery } from "@/pages/Private/redux/projects.api";
import { Dashboard } from "@/enum";

import { ProjectBrandOverviewDashboard } from "./OverviewDashboard/ProjectBrandOverviewDashboard";
import { ProjectByBrandSalesDashboard } from "./SalesDashboard/ProjectByBrandSalesDashboard";
import { SettingsSection } from "./components/SettingsSection";
import { ProjectByBrandDoiDashboard } from "./DOIDashboard/ProjectByBrandDoiDashboard";
import { ProjectByBrandActivationsDashboard } from "./ActivationsDashboard/ProjectByBrandActivationsDashboard";
import { ProjectByBrandGiveawaysDashboard } from "./GiveawaysDashboard/ProjectByBrandGiveawaysDashboard";
import { ProjectByBrandPromoterDashboard } from "./PromoterDashboard/ProjectByBrandPromoterDashboard";

export const ProjectDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { projectId = 0, brandId = 0, dashboard = Dashboard.OVERVIEW } = useParams();

  const { isFetching } = useGetProjectByBrandKpiQuery(
    { projectId: Number(projectId), brandId: Number(brandId), dashboard },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedProjectByBrand());
    };
  }, [dispatch]);

  const renderElement = useMemo(() => {
    switch (dashboard) {
      case Dashboard.SALES:
        return <ProjectByBrandSalesDashboard />;
      case Dashboard.DOI:
        return <ProjectByBrandDoiDashboard />;
      case Dashboard.ACTIVATIONS:
        return <ProjectByBrandActivationsDashboard />;
      case Dashboard.GIVEAWAYS:
        return <ProjectByBrandGiveawaysDashboard />;
      case Dashboard.PROMOTER:
        return <ProjectByBrandPromoterDashboard />;

      default:
        return <ProjectBrandOverviewDashboard />;
    }
  }, [dashboard]);

  return isFetching ? (
    <LoadingOverlay />
  ) : (
    <Box sx={{ pt: { md: 1 } }}>
      <SettingsSection selectedDashboard={dashboard} />
      {renderElement}
    </Box>
  );
};
