import {
  Paper,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Pagination,
  Box,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";

export interface TableColumn<DataType> {
  title: string;
  sortable?: boolean;
  render: (item: DataType, options?: { isLast?: boolean }) => JSX.Element;
  width?: number | string;
  align?: "left" | "right" | "center";
  onClick?: () => void;
}

interface TableProps<DataType extends { id: string | number } = { id: string | number }> {
  data: DataType[];
  columns: TableColumn<DataType>[];
  isLoading?: boolean;
  page: number;
  totalPages: number;
  handleFetchPage: (page: number) => void;
  hidePagination?: boolean;
  noDataText?: string;
  hideHeader?: boolean;
  allowNavigation?: boolean;
  sortOrder?: "asc" | "desc" | null;
}

export function TableComponent<DataType extends { id: string | number }>({
  data,
  columns,
  isLoading,
  page,
  totalPages,
  handleFetchPage,
  hidePagination,
  noDataText,
  hideHeader,
  allowNavigation,
  sortOrder,
}: TableProps<DataType>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const handleSort = (column: TableColumn<DataType>) => (column.onClick ? column.onClick() : null);

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <>
      <Paper
        sx={{
          margin: "1.6rem auto 0",
          padding: "0 12px 16px 12px",
          borderRadius: 2,
        }}
      >
        <Table
          aria-label="sticky table"
          sx={{
            position: "relative",
            overflow: "none",
          }}
        >
          {!hideHeader && (
            <TableHead
              sx={{
                position: { xs: "", md: "sticky" },
                zIndex: 100,
                top: "0px",
                backgroundColor: "white",
              }}
            >
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      width: column.width ?? "auto",
                      padding: 0,
                      cursor: column.sortable ? "pointer" : "default",
                    }}
                    onClick={() => handleSort(column)}
                  >
                    <Typography
                      component="p"
                      sx={{
                        marginY: 2,
                        marginRight: column.title.length > 15 ? 2 : 0,
                        textAlign: column.align,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="gray14Bold"
                    >
                      {column.title}
                      {column.sortable && (
                        <Box component="span" sx={{ marginLeft: "4px" }}>
                          {sortOrder === "asc" && <ArrowUpwardIcon sx={{ fontSize: "1rem" }} />}
                          {sortOrder === "desc" && <ArrowDownwardIcon sx={{ fontSize: "1rem" }} />}
                        </Box>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody
            sx={{
              height: data.length ? "auto" : "150px",
            }}
          >
            {data && data.length > 0 ? (
              data.map((row, dataIndex) => (
                <TableRow
                  key={row.id}
                  component="a"
                  href={`${location.pathname}/${row.id}`}
                  sx={{ cursor: allowNavigation ? "pointer" : "", textDecoration: "none" }}
                  onClick={(e) => {
                    if (e.ctrlKey || e.metaKey) {
                      return;
                    }

                    e.preventDefault();
                    if (allowNavigation) {
                      navigate({ pathname: `${row.id}`, search: searchParams.toString() });
                    }
                  }}
                >
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      component="td"
                      scope="row"
                      sx={{
                        minWidth: column.width ?? "auto",
                        padding:
                          dataIndex === data?.length - 1 ? "16px 0px 0 0" : "16px 0px 16px 0",
                        border: dataIndex === data?.length - 1 ? "none" : "",
                        textAlign: column.align,
                      }}
                    >
                      {column.render(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} sx={{ textAlign: "center", border: "none" }}>
                  <Typography fontSize={24} variant="gray14Bold">
                    {noDataText ?? t("basics.noData")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {!hidePagination && totalPages > 1 && (
        <Box my={2}>
          <Pagination
            count={totalPages}
            page={page}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            onChange={(_: unknown, page: number) => handleFetchPage(page)}
          />
        </Box>
      )}
    </>
  );
}
