import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { ColumnBarChart } from "@/components/ColumnBarChart/ColumnBarChart";
import { Loader, NoDataFound } from "@/components";
import { convertChartStringData } from "@/app/utils";

import { EventDataType, KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface SoldProductsByBrandProps {
  isFiltration?: boolean;
  isFetching: boolean;
  salesKPIs: KpiType[];
}

export const SoldProductsByBrand: FunctionComponent<SoldProductsByBrandProps> = ({
  isFiltration,
  isFetching,
  salesKPIs,
}) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.salesKPIs.byProductByBrand.${key}`);

  const [maxValue, setMaxValue] = useState(0);

  const salesByBrandByProductKPIs = salesKPIs.find(
    (kpi) => kpi.name === KpiTypeEnum.SALES_BY_BRANDFAMILY_BY_PRODUCT
  )?.data;

  //This converts the KPI string values into proper Apex chart input
  const convertNameValues = useCallback(
    (data: EventDataType[], arr: string[]) => {
      const values = convertChartStringData({ enumValues: arr, data, isFiltration });

      return values;
    },
    [isFiltration]
  );

  const productNames = useMemo(() => {
    const currEventProducts = salesByBrandByProductKPIs?.map((el) => el.specific) || [];

    return convertNameValues(
      salesByBrandByProductKPIs?.filter((data) => data.specific) || [],
      currEventProducts
    );
  }, [salesByBrandByProductKPIs, convertNameValues]);

  //This converts the KPI numeric values into proper Apex chart input
  const convertToStackedChartSeries = useCallback(
    ({
      enumValues,
      data,
      isFiltration,
    }: {
      enumValues: string[];
      data: EventDataType[];
      isFiltration?: boolean;
    }) => {
      const setSeriesDataArr = (data: EventDataType[], value: number, i: number) => {
        const series = Array.from({ length: data.length }, () => 0);

        //this calculates and sets the max value for the yaxis in the chart
        const totalSales = data[i].date.reduce((totalSalesValue, sales) => {
          totalSalesValue += sales.value;

          return totalSalesValue;
        }, 0);

        if (maxValue < totalSales) {
          setMaxValue(totalSales);
        }

        series[i] = value;

        return series;
      };

      return enumValues.flatMap((name, index) =>
        data.flatMap((eventData) =>
          name === eventData.specific
            ? eventData.date.map((prodData) =>
                Object({
                  name: prodData.dim2,
                  data: setSeriesDataArr(data, Math.floor(prodData.value), index),
                })
              )
            : []
        )
      );
    },
    [maxValue]
  );

  const convertData = useCallback(
    (data: EventDataType[], arr: string[]) => {
      return convertToStackedChartSeries({ enumValues: arr, data, isFiltration });
    },
    [isFiltration, convertToStackedChartSeries]
  );

  const salesByBrandValues = useMemo(() => {
    const currEventProducts = salesByBrandByProductKPIs?.map((prod) => prod.specific) || [];

    return convertData(
      salesByBrandByProductKPIs?.map((prod) => {
        return {
          ...prod,
          date: prod.date.filter((data) => (isFiltration ? data.value : data.value && !data.date)),
        };
      }) || [],
      currEventProducts
    );
  }, [salesByBrandByProductKPIs, convertData, isFiltration]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: { xs: 3, md: 0 },
        mr: { xs: 0, md: 1 },
        bgcolor: { md: "white" },
        borderRadius: 2,
        boxShadow: 1,
        p: { xs: 1, md: 2 },
        width: { md: "70%" },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h3" variant="mainBold">
          {ts("title")}
        </Typography>
        <Typography variant="gray12">{t("events.twentySticks")}</Typography>
      </Box>
      {isFetching ? (
        <Loader />
      ) : salesByBrandByProductKPIs ? (
        <ColumnBarChart stacked categories={productNames} series={salesByBrandValues} />
      ) : (
        <NoDataFound height="136px" kpi={ts("title").toLowerCase()} />
      )}
    </Box>
  );
};
