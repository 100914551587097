import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/app/utils";

import { EventSchema, WeeklyOpeningTimesSchema } from "../../Events/event.schema";
import { DataSourceSchema } from "../../DataSources/schema/dataSource.schema";

export const ModuleSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  externalId: z.string(),
  companyId: z.number().nullable(),
  dataSources: z.array(z.null()),
  events: z.array(EventSchema).nullable(),
});

export const EventModuleSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  externalId: z.string(),
  companyId: z.number(),
  eventId: z.number(),
});

export const ModulesResponseSchema = z.object({
  data: ModuleSchema.array(),
  meta: MetaDataSchema,
});

const { REQUIRED } = ERROR_TYPE;
export const CreateRequestSchema = z.object({
  name: z.string().min(2, { message: REQUIRED }).trim(),
  type: z.string().nonempty(),
  companyId: z.number(),
});

export const UpdateRequestModuleBodySchema = z.object({
  name: z.string().min(2, { message: REQUIRED }).trim(),
  type: z.string().nonempty(),
  companyId: z.number(),
});

export const UpdateRequestModuleSchema = z.object({
  id: z.number(),
  body: UpdateRequestModuleBodySchema,
});

export const DeleteModuleSchema = z.object({
  moduleId: z.number(),
});

export const ModuleAddressSchema = z.object({
  street: z.string().min(1, { message: REQUIRED }),
  houseNumber: z.string().min(1, { message: REQUIRED }),
  city: z.string().min(1, { message: REQUIRED }),
  zip: z.string().min(1, { message: REQUIRED }),
  nielsen: z.string().min(1, { message: REQUIRED }),
});

export const OpeningHoursSchema = z.object({
  eventId: z.number(),
  module: ModuleSchema,
  dataSources: z.array(DataSourceSchema),
  weeklyOpeningTimes: WeeklyOpeningTimesSchema,
  startDate: z.string().datetime(),
  endDate: z.string().datetime(),
  address: ModuleAddressSchema,
});
export const OpeningHoursBodySchema = z.object({
  eventId: z.number(),
  module: z.number(),
  startDate: z.string().min(1, { message: "Date should not be empty" }),
  endDate: z.string().min(1, { message: "Date should not be empty" }),
  timezone: z.string().optional(),
  weeklyOpeningTimes: WeeklyOpeningTimesSchema.refine(
    (weeklyOpeningTimes) => {
      const daysWithOpeningTimes = Object.values(weeklyOpeningTimes).filter(
        (weekDay) => weekDay?.isOpened
      );

      const hasAtLeastOneOpenDay = daysWithOpeningTimes.some(
        (weekDay) => weekDay.fromTime.length && weekDay.tillTime.length
      );

      if (!hasAtLeastOneOpenDay) return false;

      const allSelectedDaysAreValid = daysWithOpeningTimes.every(
        (weekDay) => weekDay?.isOpened && weekDay.fromTime.length && weekDay.tillTime.length
      );

      return allSelectedDaysAreValid;
    },
    {
      message:
        "At least one day should have opening times and all selected days must have complete times.",
    }
  ),
  address: ModuleAddressSchema.refine(
    (moduleAddress) => {
      return Object.values(moduleAddress).every((field) => field.length > 1);
    },
    { message: "This field is required" }
  ),
});
const UpdateOpeningHoursRequestSchema = z.object({
  id: z.number(),
  body: OpeningHoursBodySchema,
});

export const AssignModuleSchema = z.object({
  moduleId: z.number(),
  dataSourceIds: z.array(z.number().nullable()),
});
export const AssignModuleBodySchema = z.object({
  modules: z.array(AssignModuleSchema),
});
export const AssignModuleRequestSchema = z.object({
  id: z.number(),
  body: AssignModuleBodySchema,
});

export type ModulesResponse = z.infer<typeof ModulesResponseSchema>;
export type ModuleType = z.infer<typeof ModuleSchema>;
export type EventModuleSchemaType = z.infer<typeof EventModuleSchema>;
export const CreateModuleResponseSchema = ModuleSchema.partial();
export type CreateRequestModuleType = z.infer<typeof CreateRequestSchema>;
export type UpdateModuleBodyType = z.infer<typeof UpdateRequestModuleBodySchema>;
export type UpdateModuleRequestType = z.infer<typeof UpdateRequestModuleSchema>;
export type DeleteModuleType = z.infer<typeof DeleteModuleSchema>;
export type AssignModulesType = z.infer<typeof AssignModuleSchema>;
export type AssignModuleBodyType = z.infer<typeof AssignModuleBodySchema>;
export type AssignModuleRequestType = z.infer<typeof AssignModuleRequestSchema>;
export type OpeningHoursType = z.infer<typeof OpeningHoursSchema>;
export type OpeningHoursBodyType = z.infer<typeof OpeningHoursBodySchema>;
export type UpdateOpeningHoursRequestType = z.infer<typeof UpdateOpeningHoursRequestSchema>;
