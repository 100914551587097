import { z } from "zod";

import { ERROR_TYPE } from "@/app/utils";

import { BrandSchema } from "../Brands/schema/brand.schema";
import { BudgetTypeEnum, DirectionType, EventStatus, EventTypes, KpiTypeEnum } from "./types";

const { REQUIRED } = ERROR_TYPE;

export const DateSchema = z
  .object({
    value: z.number(),
    date: z.string().datetime(),
    percent: z.number().optional(),
    dim2: z.string(),
    dim3: z.string(),
    dim4: z.string(),
    dim5: z.string(),
  })
  .array();

export const TimeSchema = z
  .object({
    dim2: z.string(),
    dim3: z.string(),
    dim4: z.string(),
    dim5: z.string(),
    value: z.number(),
    percent: z.number().optional(),
    time: z.string().datetime(),
  })
  .array()
  .optional();

export const EventDataSchema = z.object({
  specific: z.string(),
  overall: z.object({
    value: z.number(),
    dim2: z.string().nullable(),
    dim3: z.string().nullable(),
    dim4: z.string().nullable(),
    dim5: z.string().nullable(),
    percent: z.number().nullable(),
  }),
  date: DateSchema,
  time: TimeSchema,
  hour: TimeSchema,
});

export const KpiSchema = z.object({
  name: z.nativeEnum(KpiTypeEnum),
  target: z.number(),
  target2: z.number(), // TECH DEBT - BA-706
  isActive: z.boolean(),
  data: EventDataSchema.array(),
});

export const EventDatesSchema = z.object({
  id: z.number(),
  eventId: z.number(),
  fromDateTime: z.string().datetime(),
  tillDateTime: z.string().datetime(),
  dataAvailabilityCount: z.number(),
});

export const EventDatesRequestSchema = z.object({
  fromDateTime: z.string().datetime(),
  tillDateTime: z.string().datetime(),
});

export const EventDatesRequestBodySchema = z.object({
  date: z.string().min(1, { message: REQUIRED }),
  fromDateTime: z.string().min(1, { message: REQUIRED }),
  tillDateTime: z.string().min(1, { message: REQUIRED }),
  isAfterMidnight: z.boolean(),
});

export const EventGoalsSchema = z.object({
  type: z.nativeEnum(KpiTypeEnum),
  actual: z.number(),
  planned2: z.number().nullable(), // TECH DEBT - BA-706
  planned: z.number().nullable(),
  kpiWeighting: z.number(),
  completed: z.boolean(),
  isActualEditable: z.boolean(),
  isActive: z.boolean(),
});

export const EventBudgetSchema = z.object({
  type: z.nativeEnum(BudgetTypeEnum),
  planned: z.number(),
  actual: z.number(),
  completed: z.boolean(),
});

export const EventModuleSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.nativeEnum(KpiTypeEnum),
  externalId: z.string(),
  companyId: z.number().nullable(),
  dataSources: z.array(z.null()),
});

export const ModuleConfigsSchema = z.object({
  id: z.number(),
  moduleId: z.number(),
  eventId: z.number(),
  dataSourceId: z.number(),
  direction: z.nativeEnum(DirectionType),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

const ModuleEventDataSourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  externalId: z.string(),
  type: z.string(),
  companyId: z.number().nullable(),
  moduleId: z.number().nullable(),
});

const WeekDayTimesSchema = z.object({
  fromTime: z.string(),
  tillTime: z.string(),
  isOpened: z.boolean(),
});
export const WeeklyOpeningTimesSchema = z.object({
  monday: WeekDayTimesSchema.optional(),
  tuesday: WeekDayTimesSchema.optional(),
  wednesday: WeekDayTimesSchema.optional(),
  thursday: WeekDayTimesSchema.optional(),
  friday: WeekDayTimesSchema.optional(),
  saturday: WeekDayTimesSchema.optional(),
  sunday: WeekDayTimesSchema.optional(),
});

export const ModuleEventSchema = z.object({
  module: EventModuleSchema,
  dataSources: ModuleEventDataSourceSchema.array(),
  startDate: z.string().datetime().min(1, { message: REQUIRED }).trim(),
  endDate: z.string().datetime().min(1, { message: REQUIRED }).trim(),
  weeklyOpeningTimes: WeeklyOpeningTimesSchema,
  address: z.object({
    street: z.string().min(1, { message: REQUIRED }).trim(),
    houseNumber: z.string().min(1, { message: REQUIRED }).trim(),
    city: z.string().min(1, { message: REQUIRED }).trim(),
    zip: z.string().min(1, { message: REQUIRED }).trim(),
    nielsen: z.string().min(1, { message: REQUIRED }).trim(),
  }),
});

export const EventOverallKpiDataSchema = z.object({
  sum: z.number(),
  kpi: z.nativeEnum(KpiTypeEnum),
  specific: z.string(),
});

export const EventOverallKpiSchema = z.object({
  data: EventOverallKpiDataSchema.array(),
});

export const EventSchema = z.object({
  id: z.number().gt(0),
  name: z.string(),
  externalId: z.string().nullable(),
  expectedVisitors: z.number(),
  actualVisitors: z.number().optional(),
  visitorsReach: z.number(),
  startDate: z.string().datetime(),
  endDate: z.string().datetime(),
  status: z.nativeEnum(EventStatus),
  image: z.string(),
  dataSources: z.number(),
  modules: z.number(),
  moduleConfigs: ModuleConfigsSchema.array(),
  moduleEventConfigs: ModuleEventSchema.array(),
  mt: z.number(),
  bimpact: z.number().optional(),
  bscore: z.number().optional(),
  street: z.string(),
  houseNumber: z.string(),
  city: z.string(),
  zip: z.string(),
  nielsen: z.string(),
  data: KpiSchema.array(),
  goals: EventGoalsSchema.array(),
  groupId: z.number(),
  budgets: EventBudgetSchema.array(),
  eventDates: EventDatesSchema.array(),
  companyId: z.number().nullable(),
  brand: BrandSchema.nullable(),
  brandId: z.number().nullable(),
  project: z.object({ id: z.number(), name: z.string() }).nullable(),
  projectId: z.number().nullable(),
  eventType: z.nativeEnum(EventTypes),
  notes: z.string().nullable(),
  isComplete: z.boolean().optional(),
  kpiScore: z.object({
    plannedKpiScore: z.number(),
    planned2KpiScore: z.number(),
  }),
});

/**Event schema without address, used only for Mobile events currently */
const NoAddressEventObject = {
  eventType: z.string().min(1, { message: REQUIRED }),
  name: z.string().min(1, { message: REQUIRED }),
  externalId: z.string().optional(),
  companyId: z.number().optional(),
  projectId: z.number().min(1, { message: REQUIRED }),
  brandId: z.number().min(1, { message: REQUIRED }),
  image: z.string().optional(),
};
export const NoAddressEventRequestBodySchema = z.object(NoAddressEventObject);

export const EventRequestBodySchema = NoAddressEventRequestBodySchema.extend({
  eventDates: EventDatesRequestBodySchema.array().min(1, { message: REQUIRED }),
  street: z.string().min(1, { message: REQUIRED }),
  houseNumber: z.string().min(1, { message: REQUIRED }),
  city: z.string().min(1, { message: REQUIRED }),
  zip: z.string().min(1, { message: REQUIRED }),
  nielsen: z.string().min(1, { message: REQUIRED }),
});

export const EventRequestSchema = z.object({
  name: z.string(),
  externalId: z.string().optional(),
  projectId: z.number(),
  brandId: z.number(),
  eventType: z.string(),
  companyId: z.number().optional(),
  eventDates: EventDatesRequestSchema.array().optional(),
  expectedVisitors: z.number().optional(),
  actualVisitors: z.number().optional(),
  street: z.string().optional(),
  houseNumber: z.string().optional(),
  city: z.string().optional(),
  zip: z.string().optional(),
  nielsen: z.string().optional(),
  image: z.string().optional(),
  goals: z.object({}).optional(),
  budgets: z.object({}).optional(),
  notes: z.string().optional(),
});

//Schemas below are used for the Schedule import of a module for event
export const ImportScheduleBodySchema = z.object({
  id: z.number(),
  excelFile: z.instanceof(FormData),
});
const IgnoredRows = z.object({
  row: z.number(),
  ignoredCells: z.string().array(),
});
const DataSource = z.object({
  name: z.string(),
  externalId: z.string(),
  type: z.string(),
  eventId: z.number(),
});
const Locations = z.object({
  name: z.string(),
  type: z.string(),
  eventId: z.number(),
  companyId: z.number(),
});
const Teams = z.object({
  name: z.string(),
  type: z.string(),
  eventId: z.number(),
  companyId: z.number(),
});
const InsertedDataPerRow = z.object({
  insertedFromRow: z.number(),
  eventDate: EventDatesSchema,
  dataSourcesForEventDate: DataSource.array(),
  locationForEventDate: z.string(),
  teamForEventDate: z.string(),
});
const InsertedData = z.object({
  dataSources: DataSource.array(),
  locations: Locations.array(),
  teams: Teams.array(),
  insertedDataPerRow: InsertedDataPerRow.array(),
});
export const ImportScheduleResponseSchema = z.object({
  errors: z.string().array().optional(),
  ignoredRows: IgnoredRows.array().optional(),
  insertedData: InsertedData.optional(),
});

export type EventType = z.infer<typeof EventSchema>;
export type KpiType = z.infer<typeof KpiSchema>;
export type EventDatesType = z.infer<typeof EventDatesSchema>;
export type EventRequestType = z.infer<typeof EventRequestSchema>;
export type NoAddressEventRequestBodyType = z.infer<typeof NoAddressEventRequestBodySchema>;
export type EventRequestBodyType = z.infer<typeof EventRequestBodySchema>;
export type EventDatesRequestBodyType = z.infer<typeof EventDatesRequestBodySchema>;
export type EventGoalsType = z.infer<typeof EventGoalsSchema>;
export type EventBudgetType = z.infer<typeof EventBudgetSchema>;
export type EventDataType = z.infer<typeof EventDataSchema>;
export type EventModuleType = z.infer<typeof EventModuleSchema>;
export type ImportScheduleType = z.infer<typeof ImportScheduleResponseSchema>;
export type FetchImportedScheduleType = z.infer<typeof InsertedData>;
export type ImportScheduleRequestType = z.infer<typeof ImportScheduleBodySchema>;
