import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/app/redux";

import { modulesApi } from "./modules.api";
import { ModuleState } from "../pages/Modules/types";

const initialState: ModuleState = {
  modules: [],
  eventModules: [],
  mobileEventModules: [],
  moduleDetails: null,
  filters: {
    moduleType: null,
    locationIds: [],
    teamId: null,
  },
};

export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    setModulesFilter: (action, { payload }) => {
      action.filters = {
        moduleType: payload.moduleType ?? initialState.filters.moduleType,
        locationIds: payload.locationIds ?? initialState.filters.locationIds,
        teamId: payload.teamId ?? initialState.filters.teamId,
      };
    },
    setEventModules: (state, { payload }) => {
      state.eventModules = payload;
    },
    setMobileEventModules: (state, { payload }) => {
      state.mobileEventModules = payload;
    },
    clearEventModules: (action) => {
      action.eventModules = initialState.eventModules;
      action.mobileEventModules = initialState.mobileEventModules;
    },
    clearModulesFilter: (action) => {
      action.filters.moduleType = initialState.filters.moduleType;
      action.filters.locationIds = initialState.filters.locationIds;
      action.filters.teamId = initialState.filters.teamId;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(modulesApi.endpoints.getModules.matchFulfilled, (state, { payload }) => {
        state.modules = payload.data;
      })
      .addMatcher(modulesApi.endpoints.getModulesByEventId.matchFulfilled, (state, { payload }) => {
        state.eventModules = payload;
      })
      .addMatcher(
        modulesApi.endpoints.getModulesByMobileEventId.matchFulfilled,
        (state, { payload }) => {
          state.mobileEventModules = payload;
        }
      )
      .addMatcher(modulesApi.endpoints.getModuleById.matchFulfilled, (state, { payload }) => {
        state.moduleDetails = payload;
      }),
});

export const { setModulesFilter, clearEventModules, clearModulesFilter } = modulesSlice.actions;
export const modulesReducer = modulesSlice.reducer;
export const modulesSelector = (state: RootState) => state.modules;
