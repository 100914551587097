import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CloseRounded, MenuRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { jtiLogo, GradientImage, poweredByLogo, bSureLogo } from "@/assets";
import { ActiveBarEnum, ProductVersion } from "@/types";
import { setActiveBar, sidebarSelector } from "@/pages/Private/redux/sidebar.slice";
import { useAppSelector } from "@/app/redux/hooks";
import { ROUTE_CONFIG } from "@/app/routes/config";
import { eventsSelector } from "@/pages/Private";
import { EventStatusBox } from "@/pages/Private/pages/Events/pages/components";
import { formatEventDates } from "@/app/utils/helpers";
import { useProductVersion } from "@/app/hooks/useProductVersion";
import { projectsSelector } from "@/pages/Private/redux/projects.slice";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { RadialBarChart } from "@/components/RadialBarChart/RadialBarChart";
import { useIsFeatureActive } from "@/app/hooks/useIsFeatureActive";
import { Features } from "@/pages/Private/pages/Companies/enums";

import { Navigation } from "../Navigation/Navigation";

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentProductVersion = useProductVersion();
  const { activeBar } = useAppSelector(sidebarSelector);
  const { selectedEvent, eventDetails, kpiTarget2TypeSwitchedOn } = useAppSelector(eventsSelector);
  const { selectedProjectByBrand } = useAppSelector(projectsSelector);
  const { user } = useAppSelector(authSelector);

  const event = selectedEvent ?? eventDetails ?? null;
  const isKpiWeightingActive = useIsFeatureActive(Features.KPI_WEIGHTING);

  const kpiScore = eventDetails
    ? kpiTarget2TypeSwitchedOn
      ? eventDetails.kpiScore?.planned2KpiScore
      : eventDetails.kpiScore?.plannedKpiScore
    : 0;

  return (
    <>
      <Box
        alignItems="center"
        boxShadow={1}
        display="flex"
        height={32}
        justifyContent="space-between"
        justifyItems="center"
        padding={2}
        position="relative"
        sx={{
          zIndex: 100,
          height: { md: 42 },
        }}
      >
        <Box alignItems="center" display="flex">
          {currentProductVersion === ProductVersion.JTI ? (
            <img
              alt=""
              src={user?.company?.id === 1 ? jtiLogo : bSureLogo}
              style={{ height: 32, cursor: "pointer" }}
              onClick={() => navigate(ROUTE_CONFIG.EVENTS)}
            />
          ) : (
            <img
              alt=""
              src={bSureLogo}
              style={{ width: "fit", height: 32, cursor: "pointer" }}
              onClick={() => navigate(ROUTE_CONFIG.EVENTS)}
            />
          )}
          <Box ml={1.5} sx={{ display: { xs: "none", md: "block" } }}>
            {event &&
              matchRoutes([{ path: `${ROUTE_CONFIG.EVENT_DETAILS}/*` }], currentLocation) && (
                <Box
                  alignItems="center"
                  bgcolor="white"
                  borderRadius={2}
                  boxShadow={1}
                  display="flex"
                  height={54}
                  ml={5}
                  p="10px"
                >
                  <Box
                    alignItems="flex-end"
                    display="flex"
                    flexGrow={1}
                    justifyContent="space-between"
                  >
                    <Box display="flex">
                      <img
                        alt="#"
                        src={event.image}
                        style={{
                          width: 54,
                          height: 54,
                          borderRadius: 8,
                          marginRight: 8,
                          objectFit: "cover",
                        }}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
                        <Typography
                          color="gray900.main"
                          component="p"
                          fontSize={14}
                          fontWeight={400}
                          mb={1}
                        >
                          {event.name}
                        </Typography>
                        <Box alignItems="center" display="flex">
                          <Box alignItems="center" borderColor="gray400.main" display="flex">
                            {event?.brand?.image ? (
                              <Box height={24} mr={1} width={24}>
                                <img
                                  alt="#"
                                  src={event.brand.image}
                                  style={{ width: "100%", height: "100%", borderRadius: "100%" }}
                                />
                              </Box>
                            ) : (
                              <Box
                                bgcolor="main"
                                borderRadius="100%"
                                height={24}
                                mr={1}
                                width={24}
                              ></Box>
                            )}
                          </Box>
                          <Typography component="p" fontWeight={700} mr={1} variant="gray14Bold">
                            {formatEventDates({
                              startDate: event.startDate,
                              endDate: event.endDate,
                            })}
                          </Typography>
                          <EventStatusBox smaller status={event.status} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: 1,
                      paddingLeft: 1,
                      alignItems: "center",
                      justifyItems: "space-between",
                      borderStyle: "solid",
                      borderWidth: "0 0 0 1px",
                      borderColor: "gray400.main",
                      height: "60px",
                      width: "fit-content",
                    }}
                  >
                    <Typography maxWidth={"100px"} variant="gray14Bold">
                      {t(
                        isKpiWeightingActive ? "events.kpiWeightedScore" : "events.kpiAverageScore"
                      )}
                    </Typography>
                    <RadialBarChart series={[kpiScore]} />
                  </Box>
                </Box>
              )}
            {selectedProjectByBrand && (
              <Box
                alignItems="center"
                bgcolor="white"
                borderRadius={2}
                boxShadow={1}
                display="flex"
                height={54}
                ml={5}
                p="10px"
              >
                <Box
                  alignItems="flex-end"
                  display="flex"
                  flexGrow={1}
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <img
                      alt="#"
                      src={selectedProjectByBrand.image}
                      style={{
                        width: 54,
                        height: 54,
                        borderRadius: 8,
                        marginRight: 8,
                        objectFit: "cover",
                      }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
                      <Typography
                        color="gray900.main"
                        component="p"
                        fontSize={14}
                        fontWeight={400}
                        mb={1}
                      >
                        {selectedProjectByBrand.projectName}
                      </Typography>
                      <Typography component="p" fontWeight={700} mr={1} variant="gray14Bold">
                        {selectedProjectByBrand.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {!event && !selectedProjectByBrand && (
              <Box>
                <Typography color="gray800.main" fontSize={14} fontWeight={700}>
                  {t("basics.title")}
                </Typography>
                {currentProductVersion === ProductVersion.JTI && user?.company?.id === 1 && (
                  <img alt="#" height="16px" src={poweredByLogo} />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {!activeBar ? (
              <div onClick={() => dispatch(setActiveBar(ActiveBarEnum.MENU))}>
                <MenuRounded sx={{ height: 32, width: "auto" }} />
              </div>
            ) : (
              <div onClick={() => dispatch(setActiveBar(null))}>
                <CloseRounded sx={{ height: 32, width: "auto" }} />
              </div>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <Navigation />
          </Box>
        </Box>
      </Box>
      {currentProductVersion === ProductVersion.JTI && user?.company?.id === 1 && (
        <Box mt="-5px" width="100%">
          <img
            alt=""
            height="10px"
            src={GradientImage}
            style={{ objectFit: "cover", width: "100%" }}
          />
        </Box>
      )}
    </>
  );
};
