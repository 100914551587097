import { createTheme } from "@mui/material/styles";

export const jtiTheme = createTheme({
  palette: {
    white: "#FFFFFF",
    main: "#00B2A9",
    warning: {
      main: "#EAAA00",
    },
    gray100: {
      main: "#FEFEFE",
    },
    gray200: {
      main: "#F5F5F5",
    },
    gray300: {
      main: "#F6F6F6",
    },
    gray400: {
      main: "#E3E3E3",
    },
    gray500: {
      main: "#6B738B",
    },
    gray600: {
      main: "#919EAB",
    },
    gray700: {
      main: "#637381",
    },
    gray800: {
      main: "#8895BA",
    },
    gray900: {
      main: "#333333",
    },
    green: {
      main: "#00B2A9",
    },
    red: {
      main: "#B10000",
    },
    secondary: {
      main: "#8031A7",
      light: "#B9F1D8",
      dark: "#919EAB",
    },
    purple: {
      main: "#8031A7",
    },
    primary: {
      main: "#00B2A9",
      contrastText: "#fff",
    },
    badge: {
      green: "#00B2A9",
      gray: "#E9E9E9",
    },
    statuses: {
      live: {
        light: "#CEFFE9",
        medium: "#B9F1D8",
        dark: "#8031A7",
      },
      upcoming: {
        light: "#F4F6F8",
        medium: "#DFE3E8",
        dark: "#2196F3",
      },
      finished: {
        light: "#F4F6F8",
        medium: "#DFE3E8",
        dark: "#646B76",
      },
    },
    chartsPalette: {
      donutChart: ["#8031A7", "#00B2A9", "#b298dc", "#919EAB", "#B9F1D8"],
      columnBarChart: ["#8031A7", "#00B2A9", "#b298dc", "#919EAB", "#B9F1D8"],
      lineChart: ["#00B2A9", "#8031A7", "#919EAB", "#b298dc", "#B9F1D8"],
    },
    radialBarColorway: {
      gradient100: "#FFE763",
      gradient200: "#F9D97E",
      gradient300: "#F4CC97",
      gradient400: "#EDBAB8",
      gradient500: "#E6AAD6",
      gradient600: "#DF9BF4",
      gradient700: "#C398F6",
      gradient800: "#949EE3",
      gradient900: "#3AABC0",
      gradient1000: "#05B2AB",
      track: "#F3F3F3",
    },
  },
  shadows: [
    "none",
    "0px 0px 20px rgba(0, 0, 0, 0.05)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: 8,
          padding: 12,
          marginBottom: 8,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
          ":last-child": { marginBottom: 0 },
        },
      },
    },
    // TECH DEBT - BA-706
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: 8,
          marginBottom: 16,
          ":last-child": { paddingBottom: 0 },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#D9D9D9",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#333333",
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
  typography: {
    main: {
      color: "#333333",
      fontSize: 16,
      fontWeight: 500,
    },
    mainBold: {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
    main10Bold: {
      color: "#333333",
      fontSize: 10,
      fontWeight: 600,
      lineHeight: "12px",
    },
    main18Bold: {
      color: "#333333",
      fontSize: 18,
      fontWeight: 600,
    },
    main12: {
      color: "#333333",
      fontSize: 12,
      fontWeight: 500,
    },
    main14: {
      color: "#333333",
      fontSize: 14,
      fontWeight: 500,
    },
    main14Bold: {
      color: "#333333",
      fontSize: 14,
      fontWeight: 700,
    },
    main28: {
      color: "#333333",
      fontSize: 28,
      fontWeight: 500,
    },
    main28bold: {
      color: "#333333",
      fontSize: 28,
      fontWeight: 700,
    },
    main42bold: {
      color: "#333333",
      fontSize: 42,
      fontWeight: 700,
    },
    main60bold: {
      color: "#333333",
      fontSize: 60,
      fontWeight: 700,
    },
    secondaryText: {
      color: "#333333",
      fontSize: 14,
      fontWeight: 400,
    },
    gray14: {
      color: "#919EAB",
      fontSize: 14,
      fontWeight: 500,
    },
    gray14Bold: {
      color: "#919EAB",
      fontSize: 14,
      fontWeight: 600,
    },
    gray12: {
      color: "#919EAB",
      fontSize: 12,
      fontWeight: 500,
    },
    green10: {
      color: "#00B2A9",
      fontSize: 10,
      fontWeight: 500,
      lineHeight: "12.1px",
    },
    green12: {
      color: "#00B2A9",
      fontSize: 12,
      fontWeight: 600,
    },
    green: {
      color: "#00B2A9",
      fontWeight: 600,
    },
    green28: {
      color: "#00B2A9",
      fontSize: 28,
      fontWeight: 600,
    },
    link: {
      color: "#00B2A9",
      fontSize: 14,
      cursor: "pointer",
    },
    h1: {
      fontSize: 30,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
    },
    h6: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
});
