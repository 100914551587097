import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FileUploader } from "react-drag-drop-files";
import { Box, Typography } from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";

import { eventsSelector, useImportScheduleMutation } from "@/pages/Private/redux";
import { Button, ButtonSize, LoadingOverlay } from "@/components";
import { useAppSelector } from "@/app/redux/hooks";
import { useWindowSize } from "@/app/hooks/useWindowSize";
import { PARSED_ENV } from "@/app/constants/common";

import { ImportScheduleType } from "../../../../event.schema";

export const TourPlanUpload: FunctionComponent = () => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.settings.tourPlan.${key}`);
  const [uploadedFile, setUploadedFile] = useState<null | File>(null);

  const windowSize = useWindowSize();
  const { eventDetails: event } = useAppSelector(eventsSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadFile, { isSuccess, isError, isLoading }] = useImportScheduleMutation();

  // TODO: on initial page load fetch currently imported data
  // from /events/import-schedule/${eventId} and set to state
  const [importedData, setImportedData] = useState<ImportScheduleType>();
  const [message, setMessage] = useState({ color: "darkred", text: "" });
  const [dropDownStates, setDropDownStates] = useState({
    skippedRows: false,
    errors: false,
    importedData: false,
  });

  const onSubmit = async () => {
    if (uploadedFile) {
      const formData = new FormData();

      formData.append("excelFile", uploadedFile);

      type ResponseType = { data: ImportScheduleType };
      const response = (await uploadFile({
        id: event?.id ?? 0,
        excelFile: formData,
      })) as ResponseType;

      if (response.data) {
        if (response.data?.errors?.length) {
          setMessage({ color: "darkred", text: "Errors found in excel file, please fix." });
        } else {
          setMessage({ color: "green", text: "Successful excel import" });
        }

        setImportedData(response.data);
      }
    } else {
      setMessage({ color: "darkred", text: "Please select a file." });
    }
  };

  useEffect(() => {
    async function fetchImportedData() {
      const token = localStorage.getItem("token");
      const fetching = await fetch(
        `${PARSED_ENV.REACT_APP_API_URL + "/api"}/events/import-schedule/${event?.id ?? 0}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await fetching.json();

      setImportedData({
        insertedData: response,
      });
    }

    fetchImportedData();
  }, [event?.id]);

  const containerStyle: React.CSSProperties = {
    padding: 5,
    color: "green",
    border: "1px solid rgba(0,0,0,0.3)",
    borderTop: "none",
    width: "clamp(300px, 50vw, 600px)",
  };
  const dropDownTitleStyle: React.CSSProperties = {
    width: "clamp(300px, 50vw, 600px)",
    border: "1px solid rgba(0,0,0,0.3)",
    borderTop: "none",
    color: "black",
    textAlign: "center",
    cursor: "pointer",
    padding: "20px 5px",
    borderRadius: "15px 15px 0 0px",
    overflow: "hidden",
    backgroundColor: "rgba(144,238,144,0.3)",
    marginTop: 8,
  };
  const flexRowStyle: React.CSSProperties = {
    width: "clamp(100px, 19vw, 330px)",
    padding: "5px 10px",
    boxSizing: "border-box",
    border: "1px solid rgba(0,0,0,0.3)",
    borderBottom: "none",
    minHeight: "90px",
    flexGrow: 1,
  };

  return !event ? (
    <LoadingOverlay />
  ) : (
    <Box alignItems="flex-start" display="flex" flexDirection="column" mt={4}>
      <Box bgcolor="white" borderRadius={2} boxShadow={1} flexGrow={1} maxWidth={685} p={2}>
        <Typography color="gray900.main" fontSize={18} fontWeight={600}>
          {ts("title")}
        </Typography>
        <Typography color="gray600.main" fontSize={14} fontWeight={400}>
          {ts("description")}
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "start", md: "center" },
              color: "gray600.main",
              height: "100%",
              mt: 2,
            }}
          >
            <FileUploader
              handleChange={(excelFile: File) => setUploadedFile(excelFile)}
              name="excelFile"
              types={["XLS", "XLSX"]}
            >
              <Box
                sx={{
                  alignItems: "center",
                  border: "1px solid",
                  borderColor: "gray400.main",
                  borderRadius: 2,
                  cursor: "pointer",
                  display: "flex",
                  mr: 2,
                  p: 2,
                }}
              >
                <UploadFileIcon sx={{ width: 40, height: "auto", mr: 1 }} />
                {uploadedFile ? (
                  <Typography color="gray900.main" textAlign="start">
                    {uploadedFile.name}
                  </Typography>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Typography color="gray900.main" textAlign="start">
                      {windowSize.width > 900
                        ? t("basics.dragAndDropFile")
                        : t("basics.selectFile")}
                    </Typography>
                    <Typography fontSize={14} textAlign="start">
                      {ts("filesRestriction")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </FileUploader>
            <Box sx={{ mt: { xs: 1, md: 0 } }}>
              <Button handleClick={onSubmit} size={ButtonSize.M} title={ts("importData")} />
            </Box>
          </Box>
        </Box>
      </Box>
      {message.text && (
        <Box color={message.color} textAlign="center">
          {message.text}
        </Box>
      )}
      {importedData?.errors?.length && (
        <div
          style={{
            ...dropDownTitleStyle,
            backgroundColor: "rgba(255, 0, 0, 0.15)",
            borderRadius: dropDownStates.errors ? "15px 15px 0 0px" : "15px",
          }}
          onClick={() =>
            setDropDownStates((prevState) => ({
              ...prevState,
              errors: !prevState.errors,
            }))
          }
        >
          {dropDownStates.errors ? "Hide" : "Show"} error rows ({importedData?.errors?.length})
        </div>
      )}
      {dropDownStates.errors &&
        importedData?.errors?.map((errorString, index) => {
          return (
            <Box
              key={index}
              border="1px solid rgba(0,0,0,0.3)"
              borderTop="none"
              color="darkred"
              padding="5px"
              width="clamp(300px, 80vw, 600px)"
            >
              <b>{index + 1}.</b> {errorString}
            </Box>
          );
        })}
      {importedData?.ignoredRows?.length && (
        <div
          style={{
            ...dropDownTitleStyle,
            backgroundColor: "rgba(255, 145, 0, 0.2)",
            borderRadius: dropDownStates.skippedRows ? "15px 15px 0 0px" : "15px",
          }}
          onClick={() =>
            setDropDownStates((prevState) => ({
              ...prevState,
              skippedRows: !prevState.skippedRows,
            }))
          }
        >
          {dropDownStates.skippedRows ? "Hide" : "Show"} skipped rows (
          {importedData?.ignoredRows?.length})
        </div>
      )}
      {dropDownStates.skippedRows &&
        importedData?.ignoredRows?.map((ignoredRowsObject, index) => {
          const { row, ignoredCells } = ignoredRowsObject;

          return (
            <Box
              key={index}
              border="1px solid rgba(0,0,0,0.3)"
              borderTop="none"
              color="rgb(173, 121, 0)"
              padding="5px"
              width="clamp(300px, 80vw, 600px)"
              zIndex="10"
            >
              <Box padding="0 0 5px 0">
                <b>Skipped row {row} because of cells:</b>
              </Box>
              {ignoredCells.map((ignoredReasonString, ignoredCellIndex) => {
                return (
                  <Box key={ignoredCellIndex}>
                    <b>{ignoredCellIndex + 1}.</b> {ignoredReasonString}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      {importedData?.insertedData?.insertedDataPerRow?.length ? (
        <div>
          <div
            style={{
              ...dropDownTitleStyle,
              borderRadius: dropDownStates.importedData ? "15px 15px 0 0px" : "15px",
            }}
            onClick={() =>
              setDropDownStates((prevState) => ({
                ...prevState,
                importedData: !prevState.importedData,
              }))
            }
          >
            {dropDownStates.importedData ? "Hide" : "Show"} imported rows (
            {importedData?.insertedData.insertedDataPerRow?.length})
          </div>
          {dropDownStates.importedData && importedData?.insertedData.teams?.length && (
            <div style={containerStyle}>
              <Box padding="0 0 5px 0">
                <b>Imported Teams</b>
              </Box>
              {importedData?.insertedData.teams.map((teamModule, index) => {
                return (
                  <Box key={index}>
                    <b>{index + 1}.</b> {teamModule.name}
                  </Box>
                );
              })}
            </div>
          )}
          {dropDownStates.importedData && importedData?.insertedData.locations?.length && (
            <div style={containerStyle}>
              <Box padding="0 0 5px 0">
                <b>Imported Locations</b>
              </Box>
              {importedData?.insertedData.locations.map((locationModule, index) => {
                return (
                  <Box key={index}>
                    <b>{index + 1}.</b> {locationModule.name}
                  </Box>
                );
              })}
            </div>
          )}
          {dropDownStates.importedData && importedData?.insertedData.locations?.length && (
            <div style={containerStyle}>
              <Box padding="0 0 5px 0">
                <b>Imported Data Sources (Sum Up Devices)</b>
              </Box>
              {importedData?.insertedData.dataSources.map((dataSource, index) => {
                return (
                  <Box key={index}>
                    <b>{index + 1}.</b> {dataSource.name}
                  </Box>
                );
              })}
            </div>
          )}
          {dropDownStates.importedData && (
            <div
              style={{
                ...containerStyle,
                borderTop: "default",
                width: "auto",
                padding: 0,
              }}
            >
              <Box padding="17px">
                <b>Imported event dates with modules and data sources assigned to them per row</b>
              </Box>
              <Box display="flex" flexWrap="nowrap">
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px", width: 35 }}>
                  <b>#</b>
                </Box>
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px" }}>
                  <b>Location</b>
                </Box>
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px" }}>
                  <b>Date From</b>
                </Box>
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px" }}>
                  <b>Date To</b>
                </Box>
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px" }}>
                  <b>Team</b>
                </Box>
                <Box sx={{ ...flexRowStyle, minHeight: "none", padding: "5px 10px" }}>
                  <b>Data sources</b>
                </Box>
              </Box>
              {importedData?.insertedData.insertedDataPerRow.map((dataPerRow, index) => {
                const {
                  locationForEventDate,
                  teamForEventDate,
                  eventDate,
                  dataSourcesForEventDate,
                } = dataPerRow;

                const eventDateFROM = utcToZonedTime(eventDate.fromDateTime, "Europe/Berlin");
                const eventDateTO = utcToZonedTime(eventDate.tillDateTime, "Europe/Berlin");

                return (
                  <Box
                    key={index}
                    borderBottom="1px solid rgba(0,0,0,0.3)"
                    display="flex"
                    flexWrap="nowrap"
                  >
                    <Box sx={{ ...flexRowStyle, width: 35 }}>
                      <b>{index + 1}</b>
                    </Box>
                    <Box sx={flexRowStyle}>
                      <Box margin="auto 0">{locationForEventDate}</Box>
                    </Box>
                    <Box sx={flexRowStyle}>
                      <Box margin="auto 0">
                        {eventDateFROM.toDateString()} {eventDateFROM.toTimeString()}
                      </Box>
                    </Box>
                    <Box sx={flexRowStyle}>
                      <Box margin="auto 0">
                        {eventDateTO.toDateString()} {eventDateTO.toTimeString()}
                      </Box>
                    </Box>
                    <Box sx={flexRowStyle}>
                      <Box margin="auto 0">{teamForEventDate}</Box>
                    </Box>
                    <Box sx={flexRowStyle}>
                      {dataSourcesForEventDate.map((dataSource, dsIndex) => {
                        return (
                          <Box key={dsIndex} sx={{ textWrap: "nowrap" }}>
                            <i style={{ fontSize: 16 }}>{dsIndex + 1}.</i> {dataSource.name}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </Box>
  );
};
