const de = {
  translation: {
    basics: {
      title: "Event Analytics",
      poweredBy: "powered by",
      email: "E-Mail Addresse",
      password: "Passwort",
      save: "Speichern",
      backToLogin: "Zurück zum Login",
      male: "Männlich",
      female: "Weiblich",
      firstName: "Vorname",
      lastName: "Nachname",
      cancel: "Abbrechen",
      delete: "Löschen",
      home: "Startseite",
      logout: "Abmeldung",
      name: "Name",
      noData: "Keine Daten",
      notApplicable: "k.A.",
      resetPassword: "Passwort zurücksetzen",
      role: "Rolle",
      status: "Status",
      block: "Block",
      brand: "Marke",
      remove: "Entfernen",
      confirm: "Bestätigen",
      allBrands: "Alle Marken",
      kpi: "KPI",
      data: "Daten",
      day: "Tag",
      defaultSelectPlaceholder: "Bitte auswählen",
      completed: "Abgeschlossen!",
      finalize: "Fertigstellen",
      dragAndDropImage:
        "Bitte ziehen Sie das Bild per Drag & Drop oder wählen Sie es von Ihrem Gerät aus",
      dragAndDropFile: "Zum Hochladen klicken oder ziehen und ablegen",
      selectFile: "Bitte wählen Sie eine Datei",
      imageRatioNote: "Hinweis: Bilder mit einem Seitenverhältnis von 1:1 sind am besten geeignet.",
      settings: "Einstellungen",
      search: "Suche",
      clearFilter: "Filter löschen",
      beta: "BETA",
    },
    windowTitles: {
      default: "FRET Dashboard",
      eventListing: "FRET Event Listing",
      modules: "FRET Modules",
      projects: "FRET Projects",
      brands: "FRET Brands",
      users: "FRET Users",
      projectReporting: "FRET Project Reporting",
      dataSources: "FRET Data Sources",
      eventDetails: "FRET {{eventName}} Dashboard",
      eventSettings: "FRET {{eventName}} Settings",
    },
    errors: {
      invalidEmail: "Bitte geben Sie eine gültige E-Mail Adresse ein.",
      passwordGuidelinesNotMet:
        "Ihr Passwort sollte aus mindestens 8 Zeichen bestehen, darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen",
      termsAcceptanceMissing:
        "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen, um fortzufahren.",
      invalidValue: "Bitte geben Sie einen gültigen Wert ein.",
      passwordMismatch: "Die Kennwörter stimmen nicht überein.",
      invalidToken: "Ungültiges Token.",
      requiredFields: "Bitte füllen Sie die erforderlichen Felder aus.",
      wentWrong: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
      unauthorized: "Nicht autorisiert. Bitte loggen Sie sich ein.",
      userNotInGroup: "Der Benutzer ist nicht Teil einer Gruppe.",
    },
    terms: {
      brand: "Marke",
      project: "Projekt",
      selectBrand: "Marke auswählen",
      selectTeam: "Team auswählen",
      selectLocation: "Standort auswählen",
      selectStationaryTouchpoint: "Wählen Touchpoint",
      selectType: "Typ auswählen",
      selectProject: "Projekt auswählen",
    },
    weekDays: {
      0: "Sonntag",
      1: "Montag",
      2: "Dienstag",
      3: "Mittwoch",
      4: "Donnerstag",
      5: "Freitag",
      6: "Samstag",
    },
    login: {
      title: "Willkommen bei FRET",
      description: "Festival Reporting Tool",
      loginCta: "Anmeldung",
      emailPlaceholder: "Deine E-Mail Address",
      forgotPasswordCta: "Passwort vergessen?",
      passwordPlaceholder: "Dein Passwort",
      invalidData:
        "Die eingegebene E-Mail oder das Passwort sind falsch. Bitte versuchen Sie es erneut.",
      userNotConfirmed: "Konto noch nicht bestätigt. Bitte überprüfen Sie Ihre E-Mail.",
    },
    forgotPassword: {
      title: "Haben Sie Ihr Passwort vergessen?",
      description:
        "Geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verbunden ist, und wir senden Ihnen einen Link, um Ihr neues Passwort festzulegen.",
      emailPlaceholder: "Deine E-Mail Addresse",
      forgotPasswordCta: "Zurücksetzen",
      submittedFeedbackMessage:
        "Wir haben dir eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet",
      toLoginCta: "Zum Login",
    },
    setPassword: {
      title: "Bitte setze dein Passwort",
      description:
        "Ihr Passwort sollte aus mindestens 8 Zeichen bestehen, darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen",
      confirmPassword: "Passwort bestätigen",
      confirmPasswordPlaceholder: "Wiederhole dein Passwort",
      passwordPlaceholder: "Erstelle ein Passwort",
      setPasswordCta: "Passwort setzen",
    },
    resetPassword: {
      title: "Reset your password",
      description:
        "Ihr Passwort sollte aus mindestens 8 Zeichen bestehen, darunter ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen",
      confirmPassword: "Passwort bestätigen",
      confirmPasswordPlaceholder: "Wiederhole dein Passwort",
      passwordPlaceholder: "Password",
      setPasswordCta: "Reset password",
    },
    register: {
      title: "Bitte setze dein Passwort",
      confirmPassword: "Passwort bestätigen",
      confirmPasswordPlaceholder: "Wiederhole dein Passwort",
      passwordPlaceholder: "Erstelle ein Passwort",
      setPasswordCta: "Passwort setzen",
      emailAlreadyTaken: "E-Mail bereits registriert.",
    },
    events: {
      createEvent: "Ereignis erstellen",
      eventList: "Liste der Ereignisse",
      eventType: "Typ",
      searchEvent: "Ereignisse suchen...",
      visitors: "Besucher",
      visitorsExpected: "Erwartete Besucher",
      duration: "{{days}} Tage Festival",
      oneDay: "{{days}} Tagesfest",
      allEvents: "Alle Ereignisse",
      live: "Live",
      upcoming: "Upcoming",
      finished: "Finished",
      liveDataSources: "{{count}} Datenquellen live",
      bScore: "BScore",
      bImpact: "BImpact",
      module: "Modul",
      dataSource: "Datenquellen",
      mt: "MT",
      audienceEstimate: "Schätzung des Publikums",
      emotions: "Emotionen",
      reach: "erreichen",
      performanceTimeline: "Performance Timeline",
      footfallFrequency: "Footfall Frequency",
      DOI_NL: "DOI+NL",
      activations: "Aktivierungen",
      sales: "Vertrieb",
      kpiAverageScore: "KPI Durchschnittswert",
      kpiWeightedScore: "KPI Gewichteter Ergebnis",
      deviceSales: "Geräteverkäufe",
      samples: "Samples",
      ageDistribution: "Altersverteilung",
      residenceTime: "Aufenthaltsdauer",
      timeFrame: "Bezeichnung",
      finalKPIs: "Finale KPIs",
      sampling: "Sampling",
      noData: "Derzeit sind keine Daten für {{kpi}} verfügbar.",
      promoterHours: "Projektträger-Stunden",
      totalPeriod: "Zeitraum insgesamt",
      selectHour: "Stunde wählen",
      positive: "Positiv",
      neutral: "Neutral",
      negative: "Negativ",
      eventNotes: "Veranstaltungshinweise",
      noNotes: "Derzeit sind keine Notizen hinzugefügt worden",
      startTyping: "Fangen Sie an zu tippen...",
      thisKPI: "dieser KPI",
      kpiSwitch: "KPI-Schalter",
      plannedKPI: "Geplant",
      jtiKPI: "JTI Geplant",
      overview: "Übersicht",
      heatmapSalesByDays: "Sales pro Tag",
      datesList: "Liste der Termine",
      heatmapByDayAndHour: "Heatmap nach Tagen und Stunden",
      heatmapActivationsByDays: "Aktivierungen nach Tagen",
      heatmapSalesByModules: "Sales pro Modul",
      heatmapGiveawaysByModules: "Werbegeschenke nach Modulen",
      donutSalesByBrand: "Umsatzaufteilung nach Marken",
      donutSalesByDay: "Aufteilung der Verkäufe nach Tagen",
      donutDoisByDay: "DOI Aufteilung nach Tagen",
      donutSalesByCategory: "Umsatzaufteilung nach Kategorien",
      donutGiveawaysByDay: "Werbegeschenke nach Tagen aufgeteilt",
      donutGiveawaysByModule: "Werbegeschenke nach Modulen aufgeteilt",
      donutActivationsByType: "Aktivierungen aufgeteilt nach Typ",
      donutActivationsByDay: "Aktivierungen aufgeteilt nach Tag",
      moduleInsights: "Modul Einblicke",
      twentySticks: "20-Stick Pakete",
      missingProductsWarning: "Unvollständige Daten. Bitte pflegen Sie alle Daten im Cockpit!",
      missingProductsTooltipWarning: "Unvollständige Daten",
      missingModulesWarning: "Bitte konfigurieren Sie mindestens ein Module.",
      dashboardsTooltipTitle:
        "Die Daten können bis zu 30 Minuten verspätet sein. Datenimporte finden während eines Festivals alle 15 Minuten statt. Der Datentransport erfolgt ebenfalls alle 15 Minuten.",
      doi: "DOI",
      giveaways: "Werbegeschenke",
      promoters: "Projektträger",
      electronicCigaretteSticks: "ECS",
      withDeviceId: "mit Geräte-ID",
      create: {
        backToEvents: "Zurück zu Veranstaltungen",
        title: "Ereignis erstellen",
        description:
          "Bearbeiten Sie hier die Grunddaten Ihrer Veranstaltung. Erforderliche Felder sind hervorgehoben.",
      },
      settings: {
        backToEvent: "Zurück zur Veranstaltung",
        title: "Event Einstellungen",
        general: {
          title: "Allgemeine Einstellungen",
          description:
            "Verwalten Sie hier Ihre Veranstaltung. Fügen Sie Details wie den Namen, die Adresse, die Veranstaltungstage, die Marke und andere Informationen hinzu.",
          eventImage: "Ereignis-Bild",
          eventName: "Name der Veranstaltung",
          externalId: "Externe ID",
          eventCategory: "Kategorie der Veranstaltung",
          eventTime: "Datum der Veranstaltung",
          audience: "Publikum",
          startHour: "Beginn Stunde",
          endHour: "Ende der Stunde",
          eventContinue: "Die Veranstaltung wird nach dem Ende des Tages fortgesetzt",
          addDay: "Tag hinzufügen",
          expectedAudience: "Erwartetes Publikum",
          actualAudience: "Tatsächliches Publikum",
          eventLocation: "Veranstaltungsort",
          street: "Straße",
          streetNumber: "Hausnummer",
          city: "Stadt",
          postalCode: "PLZ",
          nielsen: "Nielsen",
        },
        openingTimes: {
          title: "Zeiteinstellungen für Ereignisse",
          description:
            "Hier können Sie die Arbeitstage und -stunden der Kampagne für das jeweilige Modul verwalten. Bitte wählen Sie das Start-/Enddatum für das Modul und die Arbeitsstunden für jeden Tag aus.",
          campaignDates: {
            title: "Termine der Kampagne",
            startDate: "Datum des Beginns",
            endDate: "Enddatum",
          },
          weekDays: {
            title: "Wöchentliche Öffnungszeiten",
            tooltip:
              "(Bitte wählen Sie mindestens einen der Tage aus und legen Sie die Arbeitszeiten fest)",
            startHour: "Beginn Stunde",
            endHour: "Ende der Stunde",
            closed: "-geschlossen-",
            monday: "Montags",
            tuesday: "Dienstags",
            wednesday: "Mittwochs",
            thursday: "Donnerstags",
            friday: "Freitags",
            saturday: "Samstags",
            sunday: "Sonntags",
          },
          notifications: {
            openingTimesEdited: "Moduldetails aktualisiert!",
            incompleteData:
              "Unvollständige Daten! Bitte füllen Sie alle erforderlichen Felder aus.",
            errorMessage: "Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal",
          },
        },
        tourPlan: {
          title: "Tour-Plan",
          description: "Upload tour plan in .xlsx file",
          filesRestriction: "Only .xlsx files",
          importData: "Daten importieren",
        },
        modules: {
          title: "Modul-Einstellungen ",
          pickModule: "Modul auswählen",
          selectModule: "Modul auswählen",
          addModule: "Modul hinzufügen",
          selectedModules: "Ausgewählte Module",
          description:
            "Hier können Sie festlegen, welche Module auf diesem Festival vorhanden sind und welche Datenquellen für jedes Modul verfügbar sind.",
          noModuleSelected:
            "Zurzeit gibt es für diese Veranstaltung keine Module. Bitte fügen Sie zuerst ein Modul hinzu.",
          deleteModal: {
            title: "Modul aus Ereignis entfernen",
            description: "Sind Sie sicher, dass Sie das Modul entfernen möchten:",
          },
        },
        dataSources: {
          title: "Datenquelle Einstellungen",
          description:
            "Sie können einem Modul eine oder mehrere Datenquellen hinzufügen, aber eine Datenquelle kann nur einem Modul innerhalb einer Veranstaltung hinzugefügt werden.",
          noModuleSelectedTitle: "Verfügbare Datenquellen",
          selectedDataSources: "Verfügbare Datenquellen für das Modul {{selectedModuleName}}",
          noModuleSelected:
            "Derzeit ist kein Modul ausgewählt. Bitte wählen Sie zunächst ein Modul aus.",
        },
        budget: {
          title: "Budget-Einstellungen",
          description: "Hier ist es möglich Budgets zu verwalten.",
          budgetPosition: "Haushaltsposition",
          planned: "Geplant",
          spent: "Verbraucht",
          wkz: "WKZ",
          merch: "Merch",
          freeBeer: "Freibier",
          products: "Produkte",
          food: "Lebensmittel",
          sum: "sum",
          balance: "Bilanz:",
          finalizeBudget: "Finalisieren",
          finalizeBudgetDescription:
            "Die eingegebenen Daten können mit dem 'Speichern'-Button gesichert werden. Sobald die Daten nicht mehr verändert werden dürfen, kann mithilfe des 'Finalisieren' gesperrt werden. Weitere Änderungen sind dann nicht mehr möglich.",
        },
        kpis: {
          title: "KPI-Einstellungen",
          kpi: "KPI",
          active: "Aktiv",
          planned: "Geplant",
          planned2: "JTI Geplant",
          actual: "Aktuell",
          kpiWeighting: "KPI-Gewichtung",
          description:
            "Sobald die KPIs für das Festival abgestimmt und korrekt sind, können Nutzer die Felder sperren. Wenn Du später weitere Änderungen vornehmen möchtest, kontaktiere bitte deinen Manager, der die Felder wieder entsperren kann.",
        },
        tabs: {
          general: "Allgemein",
          modules: "Module",
          tourPlanUpload: "Tour Plan Upload",
          budget: "Haushalt",
          kpis: "KPIs",
        },
      },
      kpis: {
        SALES: "Vertrieb",
        SAMPLES: "Proben",
        GIVEAWAY: "Give-Away",
        VISITORS: "Besucher",
        ACTIVATIONS: "Aktivierungen",
        PROMOTER_HOURS: "Promoter Stunden",
        NEWSLETTER_DATA: "DOI+NL",
        EMOTIONS: "EMOTIONS",
        LIVIA_DURATION: "Aufenthaltsdauer",
        MALE: "männlich",
        FEMALE: "weiblich",
        SALES_BY_MODULE: "Sales pro Modul",
        SALES_BY_PRODUCT_BY_HEATERDEVICE: "Verkauf von Heizgeräten",
        SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY: "Evo Verkäufe",
        specific: {
          DOI_NL: "DOI+NL",
          DOI: "DOI",
          PENDING: "Anhängig",
          ANONYMOUS: "Anonym",
          ALL: "Alle",
        },
      },

      topTenTables: {
        product: {
          viewAll: "alle anzeigen",
          totals: "Summen",
          title: "Produktverkäufe (Top 10)",
          popupTitle: "Produktverkäufe",
        },
        module: {
          viewAll: "alle anzeigen",
          totals: "Summen",
          title: "Modulverkäufe (Top 10)",
          popupTitle: "Modulverkäufe",
        },
        cities: {
          title: "Top 10 Registrierungen nach Städten",
          viewAll: "alle anzeigen",
          popupTitle: "Alle Registrierungen nach Städten",
          totals: "Summen",
          type: "Städte",
        },
        activations: {
          title: "Top 10 Aktivierungen",
          viewAll: "alle anzeigen",
          popupTitle: "Alle Aktivierungen",
          totals: "Summen",
          type: "Arten von Aktivierungen",
        },
        giveaways: {
          title: "Top 10 Werbegeschenke",
          viewAll: "alle anzeigen",
          popupTitle: "Alle Werbegeschenke",
          totals: "Summen",
          type: "Arten von Werbegeschenken",
        },
        ploomModule: {
          viewAll: "alle anzeigen",
          totals: "Summen",
          title: "Standort-/ Touchpoint-Verkäufe (Top 10)",
          popupTitle: "Alle Verkäufe nach Standort/ Touchpoint",
          type: "Module",
        },
        promoter: {
          doi: {
            title: {
              withoutDevice: "Liste der 30 wichtigsten Projektträger für DOI-Registrierungen",
              withDevice:
                "Liste der 30 wichtigsten Projektträger für DOI-Registrierungen mit Geräte-ID",
            },
            viewAll: "alle anzeigen",
            popupTitle: "Alle ProjektträgerInnen",
            totals: "Summen",
            type: "Projektträger",
          },
          doiNL: {
            title: {
              withoutDevice: "Liste der 30 wichtigsten Projektträger für DOI+NL-Registrierungen",
              withDevice:
                "Liste der 30 wichtigsten Projektträger für DOI+NL-Registrierungen mit Geräte-ID",
            },
            viewAll: "alle anzeigen",
            popupTitle: "Alle ProjektträgerInnen",
            totals: "Summen",
            type: "Projektträger",
          },
          withoutDOI: {
            title: {
              withoutDevice: "Liste der 30 wichtigsten Projektträger für Registrierungen ohne DOI",
              withDevice: "Top-30-Projektträgerliste für Registrierungen ohne DOI mit Geräte-ID",
            },
            viewAll: "alle anzeigen",
            popupTitle: "Alle ProjektträgerInnen",
            totals: "Summen",
            type: "Projektträger",
          },
          promoterOverViewtable: {
            email: "Email",
            doi: "DOI",
            doiWithDeviceId: "DOI mit Geräte-ID",
            doiNL: "DOI + NL",
            doiNLWithDeviceId: "DOI + NL mit Geräte-ID",
            withoutDOI: "Ohne DOI",
            pendingWithDeviceId: "Ausstehend mit Geräte-ID",
            sum: "Summe",
          },
        },
      },
      salesKPIs: {
        description:
          "Die Daten können bis zu 30 Minuten verspätet sein. Datenimporte finden während eines Festivals alle 15 Minuten statt. Der Datentransport erfolgt ebenfalls alle 15 Minuten. Alle Beträge sind auf 20-Stick-Pakete normalisiert. Beutel haben keine Normalisierung.",
        totalPacksTile: {
          title: "Verkauf von Paketen insgesamt",
          real: "echte Pakete",
        },
        totalEvoPacksTile: {
          title: "Verkäufe von Evo Packs insgesamt",
          evoPackages: "Evo-Pakete",
          real: "echte Pakete",
        },
        totalProducts: {
          title: "Produktverkäufe insgesamt",
          allTypeProducts: "Rucksäcke, Geräte und Zubehör",
        },
        totalDevice: {
          title: "Geräteverkäufe insgesamt",
        },
        totalAccessories: {
          title: "Gesamtverkauf von Zubehör",
        },

        totalDeviceAndAccessories: {
          title: "Gesamtumsatz Geräte und Zubehör",
        },
        byProductByBrand: {
          title: "Verkaufte Produkte",
        },
        byDeviceAndPacks: {
          title: "Umsatzaufteilung nach Gerät und Packungen",
        },
        byCategoryType: {
          title: "Verkaufte Produkte",
          evoPacks: "Evo-Pakete",
          heaters: "Heizungen",
          functionalAcc: "Funktionelles Zubehör",
          heaterAcc: "Zubehör für Heizgeräte",
        },
      },
      doiKPIs: {
        description:
          "Die Daten können bis zu 30 Minuten verspätet sein. Datenimporte finden während eines Festivals alle 15 Minuten statt. Der Datentransport erfolgt ebenfalls alle 15 Minuten. Die auf den Kacheln angezeigten Informationen gelten nur für DOI+NL.",
        totalRegistrations: {
          title: "DOI-Registrierungen",
          total: "DOI+NL Registrierungen",
          totalWithoutNL: "DOI ohne NL-Registrierungen",
        },
        totalRegistrationsWithDeviceId: {
          title: "DOI-Registrierungen mit Geräte-ID",
          total: "DOI+NL mit Geräte-ID",
          totalWithoutNL: "DOI mit Geräte-ID",
        },
        doiSplitByState: {
          description: "Die auf den Kacheln angezeigten Informationen gelten nur für DOI+NL.",
          title: "DOI Aufteilung nach Staat",
          states: {
            DOI_NL: "DOI+NL",
            DOI_NL_WITH_DEVICE_ID: "DOI+NL mit Geräte-ID",
            DOI: "DOI ohne NL",
            DOI_WITH_DEVICE_ID: "DOI mit Geräte-ID",
            PENDING: "Anhängig",
            PENDING_WITH_DEVICE_ID: "Ausstehend mit Geräte-ID",
            ANONYMOUS: "Anonym",
          },
        },
        doiSplitByGender: {
          title: "DOI Aufteilung nach Geschlecht",
          states: {
            male: "Herr",
            female: "Frau",
            notspecified: "k.A.",
            divers: "Divers",
          },
        },
        doiSplitByAgeGroups: {
          title: "DOI Aufteilung nach Alter",
          states: {
            notspecified: "Andere",
          },
        },
        doiByAgegroupByGender: {
          title: "DOI nach Altersgruppen und Geschlecht",
          genders: {
            male: "Herr",
            female: "Frau",
            notspecified: "k.A.",
            divers: "Divers",
          },
        },
      },
      activationsKPIs: {
        totalTile: {
          title: "Aktivierungen insgesamt",
        },
      },
      giveawaysKPIs: {
        totalTile: {
          title: "Total Giveaways",
        },
      },
      budgets: {
        WKZ: "WKZ",
        EXECUTIONS: "Executions",
        MODULAUFBAUKOSTEN: "Modulaufbaukosten",
        FILM_AND_FOTO: "Film & Foto",
      },
    },
    dataStatus: {
      title: "b.sure Live-Sensor Status",
      description: "Hier können Sie den Online-Status der b.sure-Sensoren einsehen.",
      name: "Kamera Name",
      hostname: "Hostname",
      deviceId: "Kamera ID",
      type: "Typ",
      status: "Status",
      lastStatusAt: "Letzter Stand am",
    },
    projectReporting: {
      title: "Projekt-Berichterstattung",
      description:
        "Hier können Sie eine Marke aus einem Projekt auswählen, um detaillierte Informationen zu erhalten.",
    },
    brands: {
      create: "Erstellen.",
      title: "Marken",
      description: "Erstellen und verwalten Sie hier Ihre Marken.",
      searchBrands: "Marke suchen",
      actions: "Aktionen",
      brand: "Marke",
      modals: {
        createBrand: {
          title: "Marke anlegen",
          cta: "Lege eine Marke an",
          brandNamePlaceholder: "Markenname",
        },
        editBrand: {
          title: "Marke bearbeiten",
          cta: "Eine Marke bearbeiten",
        },
      },
    },
    projects: {
      create: "Projekt erstellen",
      title: "Projekte",
      description: "Erstellen und verwalten Sie hier Ihre Projekte.",
      searchProjects: "Projekt suchen",
      actions: "Aktionen",
      project: "Projekt",
      modals: {
        createProject: {
          title: "Projekt erstellen",
          cta: "Projekt erstellen",
          projectNamePlaceholder: "Projektname",
        },
        editProject: {
          title: "Projekt bearbeiten",
          cta: "Projekt bearbeiten",
        },
      },
    },
    companies: {
      createCompany: "Erstellen",
      title: "Firmenliste",
      description: "Hier können Sie Ihre Unternehmen erstellen und verwalten.",
      searchCompanies: "Unternehmen suchen",
      actions: "Aktionen",
      company: "Unternehmen",
      backToCompanies: "Zurück zu Unternehmen",
      companyName: "Firmenname",
      companyCode: "Unternehmenscode",
      companyLogo: "Firmenlogo",
      admin: {
        firstName: "Vorname",
        lastName: "Nachname",
        email: "E-mail",
      },
      create: {
        title: "Unternehmen erstellen",
        description:
          "Fügen Sie hier die Grunddaten Ihres Unternehmens ein. Erforderliche Felder sind hervorgehoben.",
      },
      edit: {
        title: "Unternehmensdaten bearbeiten",
        description:
          "Bearbeiten Sie hier die Grunddaten Ihres Unternehmens. Erforderliche Felder sind hervorgehoben.",
      },
      notifications: {
        companyCreated: "Unternehmen erstellt",
        companyUpdated: "Unternehmen aktualisiert",
        companyDeleted: "Unternehmen gelöscht",
        wentWrong: "Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal",
      },
      modals: {
        deleteCompany: {
          confirmButton: "Ja, löschen",
          description: "Sind Sie sicher, dass Sie dieses Unternehmen löschen wollen?",
          title: "Ein Unternehmen löschen",
        },
      },
    },
    users: {
      create: "Nutzer erstellen",
      title: "Teammitglieder",
      description:
        "Verwalte deine Teammitglieder und ändere Berechtigungen. Hier ist es möglich Nutzer hinzuzufügen, ändern und löschen.",
      searchUsers: "Nutzer suchen",
      userInfo: {
        userName: "Benutzername",
        email: "E-mailadresse",
        role: "Rolle",
      },
      details: "Benutzerdetails",
      actions: "Aktionen",
      inviteUser: {
        button: "Benutzer erstellen",
        modal: {
          title: "Benutzer erstellen",
          cta: "Benutzer erstellen",
          description: "Füge deiner Organisation einen neuen Benutzer hinzu.",
          firstNamePlaceholder: "Vorname",
          lastNamePlaceholder: "Nachname",
          emailPlaceholder: "E-Mailadresse",
          rolePlaceholder: "Rolle",
          companyPlaceholder: "Benutzer Unternehmen auswählen",
        },
      },
      modals: {
        deleteUser: {
          confirmButton: "Ja, löschen",
          description: "Sind sie sicher, dass sie den Benutzer löschen möchten?",
          title: "Benutzer löschen",
        },
        editUser: {
          description: "Benutzerinformationen editieren",
          title: "Nutzer editieren",
        },
      },
    },
    modules: {
      create: "Modul erstellen",
      title: "Module",
      description: "Hier können Sie Module erstellen und bearbeiten.",
      searchModules: "Module suchen",
      moduleInfo: {
        moduleType: "Typ",
        moduleName: "Name",
      },
      details: "Modul Details",
      actions: "Aktionen",
      createModal: {
        title: "Modul erstellen",
        cta: "Modul erstellen",
        description: "Informationen für neues Modul hinzufügen",
        namePlaceholder: "Modulname",
        companyPlaceholder: "Modulunternehmen auswählen",
      },
      editModule: {
        description: "Bearbeiten Sie die Informationen über das Modul",
        title: "Modul bearbeiten",
      },
      deleteModule: {
        confirmButton: "Ja, löschen",
        description: "Sind Sie sicher, dass Sie dieses Modul löschen möchten?",
        title: "Ein Modul löschen",
      },
    },
    dataSources: {
      create: "Datenquelle erstellen",
      title: "Datenquelle erstellen",
      description: "Verwalten Sie hier Ihre Datenquellen und deren Details.",
      searchDataSources: "Datenquellen suchen",
      dataSourcesName: "Name der Datenquelle",
      details: "Angaben zur Datenquelle",
      actions: "Aktionen",
      createDataSource: {
        button: "Datenquelle erstellen",
        modal: {
          title: "Datenquelle erstellen",
          cta: "Datenquelle erstellen",
          description: "Fügen Sie eine Datenquelle Details.",
          namePlaceholder: "Name",
          externalIdPlaceholder: "Externe ID",
          typePlaceholder: "Typ auswählen",
          companyPlaceholder: "Unternehmen auswählen",
        },
      },
      modals: {
        deleteDataSource: {
          confirmButton: "Ja, löschen",
          description: "Sind Sie sicher, dass Sie diese Datenquelle löschen wollen?",
          title: "Eine Datenquelle löschen",
        },
        editDataSource: {
          description: "Bearbeiten Sie die Informationen über die Datenquelle",
          title: "Datenquelle bearbeiten",
          namePlaceholder: "Name",
          externalIdPlaceholder: "Externe ID",
          typePlaceholder: "Typ auswählen",
          companyPlaceholder: "Unternehmen auswählen",
        },
      },
    },
    navigation: {
      home: "Home",
      events: "Events",
      users: "Benutzer",
      cockpit: "Cockpit",
      companies: "Unternehmen",
      modules: "Module",
      projects: "Projekte",
      dataSources: "Datenquellen",
      settings: "Einstellungen",
      logout: "Abmelden",
    },
    notifications: {
      updatedUser: "Benutzer aktualisiert",
      invitedUser: "Benutzer eingeladen",
      deletedUser: "Benutzer löschen",
      updatedModule: "Modul aktualisiert",
      createdModule: "Modul erstellt",
      deletedModule: "Modul Gelöscht",
      eventCreated: "Ereignis wurde erfolgreich erstellt!",
      eventUpdated: "Ereignis wurde erfolgreich aktualisiert!",
      createBrand: "Marke erstellt",
      updateBrand: "Marke aktualisiert",
      createProject: "Projekt erstellt",
      updateProject: "Projekt aktualisiert",
      createDataSource: "Datenquelle erstellt",
      updateDataSource: "Datenquelle aktualisiert",
      deleteDataSource: "Datenquelle Gelöscht",
      wentWrong: "Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal",
      resetUserPassword: "E-Mail zum Zurücksetzen des Passworts gesendet",
      versionUpdate:
        "Die Anwendung ist nicht auf dem neuesten Stand! Bitte klicken Sie hier, um sie zu aktualisieren.",
      moduleFilterInfo: "Dieser Filter ist integriert: Es kann 10-20 Sekunden dauern...",
    },
  },
};

export default de;
