import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { HeatmapChart, Loader } from "@/components";
import { formatInTimeZone } from "@/app/utils";

import { KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";

interface HeatmapProps {
  kpis: KpiType[];
  isFiltration?: boolean;
  isLoading?: boolean;
}

export const ActivationsByTypeHeatmap: FunctionComponent<HeatmapProps> = ({ kpis, isLoading }) => {
  const { t } = useTranslation();
  const ts = (key: string) => t(`events.${key}`);

  const heatmapLabels = useMemo(() => {
    const activationsByTypeKPIs = kpis.find((k) => k.name === KpiTypeEnum.ACTIVATIONS)?.data;
    const labels: string[] = [];

    if (!activationsByTypeKPIs) {
      return labels;
    }

    for (const activationTypeKPI of activationsByTypeKPIs) {
      const dates = activationTypeKPI?.date?.filter((p) => !!p.date) || [];

      for (const d of dates) {
        const day: string = formatInTimeZone(new Date(d.date), "dd.MM.yyyy");

        if (!labels.includes(day)) {
          labels.push(day);
        }
      }
    }

    return labels;
  }, [kpis]);

  const heatmapData = useMemo(() => {
    const heatmapValues: {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[] = [];

    const activationsByTypeKPIs = kpis
      .find((k) => k.name === KpiTypeEnum.ACTIVATIONS)
      ?.data.filter((d) => d.specific);

    if (!activationsByTypeKPIs) {
      return heatmapValues;
    }

    for (const activationTypeKPI of activationsByTypeKPIs) {
      const activationsTypesToDisplay = activationTypeKPI?.date?.filter((d) => !!d.date);

      const activationType = activationTypeKPI?.specific as unknown as string;

      for (const type of activationsTypesToDisplay) {
        const typeFound = heatmapValues.find((v) => v.name === activationTypeKPI?.specific);
        const day: string = formatInTimeZone(new Date(type.date), "dd.MM.yyyy");

        if (typeFound) {
          const foundData = typeFound.data.find((d) => d.x === day);

          if (!foundData) {
            typeFound.data.push({
              x: day,
              y: +type.value.toFixed(2),
            });
          } else {
            foundData.y += +type.value.toFixed(2);
          }
        } else {
          const heatmapValuesData = heatmapLabels.map((dayLabel) => {
            const amountOfActivationsOnCurrentDay = dayLabel === day ? +type.value.toFixed(2) : 0;

            return {
              x: dayLabel,
              y: amountOfActivationsOnCurrentDay,
            };
          });

          heatmapValues.push({
            name: activationType,
            data: heatmapValuesData,
          });
        }
      }
    }

    return [...heatmapValues].sort((a, b) => b.name.localeCompare(a.name));
  }, [heatmapLabels, kpis]);

  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 1,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h3" variant="mainBold">
          {ts("heatmapActivationsByDays")}
        </Typography>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <HeatmapChart
          kpi={ts("heatmapActivationsByDays").toLowerCase()}
          labels={heatmapLabels}
          series={heatmapData}
        />
      )}
    </Box>
  );
};
