import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { ColumnBarChart } from "@/components/ColumnBarChart/ColumnBarChart";
import { Loader, NoDataFound } from "@/components";
import { convertChartStringData } from "@/app/utils";

import { EventDataType, KpiType } from "../../../../event.schema";
import { KpiTypeEnum } from "../../../../types";
import { Tabs } from "../../../../../../../../components/Tabs/Tabs";

interface SalesByProductTypeProps {
  isFiltration?: boolean;
  isFetching: boolean;
  salesKPIs: KpiType[];
  defaultTab: KpiTypeEnum;
}

export const SalesByProductTypeBarChart: FunctionComponent<SalesByProductTypeProps> = ({
  isFiltration,
  isFetching,
  salesKPIs,
  defaultTab,
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<KpiTypeEnum>(defaultTab);
  const ts = useCallback((key: string) => t(`events.salesKPIs.byCategoryType.${key}`), [t]);

  const salesByProductTypeKPIs = salesKPIs.find((kpi) => kpi.name === selectedTab)?.data;

  //This function the KPI string values into proper Apex chart input
  const convertNameValues = useCallback(
    (data: EventDataType[], arr: string[]) => {
      const values = convertChartStringData({ enumValues: arr, data, isFiltration });

      return values;
    },
    [isFiltration]
  );

  const productNames = useMemo(() => {
    const currEventProducts = salesByProductTypeKPIs?.map((el) => el.specific) || [];

    return convertNameValues(
      salesByProductTypeKPIs?.filter((data) => data.specific) || [],
      currEventProducts
    );
  }, [salesByProductTypeKPIs, convertNameValues]);

  //This converts the KPI numeric values into proper Apex chart input
  const convertToStackedChartSeries = useCallback(
    ({
      enumValues,
      data,
      isFiltration,
    }: {
      enumValues: string[];
      data: EventDataType[];
      isFiltration?: boolean;
    }) => {
      const setSeriesDataArr = (data: EventDataType[], value: number, i: number) => {
        const series = Array.from({ length: data.length }, () => 0);

        series[i] = value;

        return series;
      };

      return enumValues.flatMap((name, index) =>
        data.flatMap((eventData) =>
          name === eventData.specific
            ? Object({
                name: eventData.specific,
                data: setSeriesDataArr(
                  data,
                  Math.floor(
                    isFiltration ? eventData?.date?.at(0)?.value || 0 : eventData?.overall?.value
                  ),
                  index
                ),
              })
            : []
        )
      );
    },
    []
  );

  const convertData = useCallback(
    (data: EventDataType[], arr: string[]) => {
      return convertToStackedChartSeries({ enumValues: arr, data, isFiltration });
    },
    [isFiltration, convertToStackedChartSeries]
  );

  const salesByProductTypeValues = useMemo(() => {
    const currEventProducts = salesByProductTypeKPIs?.map((prod) => prod.specific) || [];

    return convertData(
      salesByProductTypeKPIs?.filter((prod) =>
        isFiltration ? prod?.date?.at(0)?.value : prod?.overall?.value
      ) || [],
      currEventProducts
    );
  }, [salesByProductTypeKPIs, isFiltration, convertData]);

  //Tab menu items
  const tabs = useMemo(() => {
    return [
      {
        text: ts(`evoPacks`),
        value: KpiTypeEnum.SALES_BY_PRODUCT_BY_CIGARETTESTYPE_ECSCATEGORY,
      },
      {
        text: ts(`heaters`),
        value: KpiTypeEnum.SALES_BY_PRODUCT_BY_HEATERDEVICE,
      },
      {
        text: ts(`functionalAcc`),
        value: KpiTypeEnum.SALES_BY_ACCESSORIES_FUNCTIONAL_BY_PRODUCT,
      },
      {
        text: ts(`heaterAcc`),
        value: KpiTypeEnum.SALES_BY_ACCESSORIES_DEVICE_BY_PRODUCT,
      },
    ];
  }, [ts]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: { xs: 3, md: 0 },
        mr: { xs: 0, md: 1 },
        bgcolor: { md: "white" },
        borderRadius: 2,
        boxShadow: 1,
        p: { xs: 1, md: 2 },
        width: { md: "70%" },
      }}
    >
      <Box
        sx={{
          display: { md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography component="h3" variant="mainBold">
            {ts("title")}
          </Typography>
          <Typography variant="gray12">{t("events.twentySticks")}</Typography>
        </Box>
        <Tabs
          excludeFromSearch
          handleSelect={(value) => setSelectedTab(value as KpiTypeEnum)}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      </Box>
      {isFetching ? (
        <Loader />
      ) : salesByProductTypeKPIs ? (
        <ColumnBarChart
          categories={productNames}
          series={salesByProductTypeValues}
          stacked={true}
        />
      ) : (
        <NoDataFound height="136px" kpi={ts("title").toLowerCase()} />
      )}
    </Box>
  );
};
